import { Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { CadastrosService } from '../../../services/cadastros.service';
import { LogOmniPOS } from '../../../model/logomnipos';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-detalhes-log',
  templateUrl: 'log-detalhes.component.html'
})

export class LogDetalhesComponent implements OnInit{

  @ViewChild('modallog', {static: false}) public modallog: ModalDirective;

  sub: boolean = false;
  log: LogOmniPOS;
  movSub9: any;

  constructor(
    private modalService: ModalService,
    private spinner: NgxSpinnerService,
    private cadastrosService: CadastrosService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesLog.subscribe(
        (p: LogOmniPOS) => {

          this.getlogsPayload(p.id)

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  async getlogsPayload(id){


    if (this.movSub9 && this.movSub9.closed == false){
      this.movSub9.unsubscribe();
    }

    if (!this.movSub9 || this.movSub9.closed){

      this.movSub9 = this.cadastrosService.listar("log/getRegistro?id="+id).subscribe(
        (result: any) =>{

          this.log = result;
          console.log("modal result");
          console.log(this.log);


          this.limpaModal();

          this.modallog.show();

       },error=>{
            console.log(error);
       });

    }


  }

  hideModal(){
    this.modallog.hide();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

}
