import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Filtro } from '../model/filtro';

@Injectable({
  providedIn: 'root'
})
export class RelatorioService {

  private URL_API = environment.APIEndpoint.valueOf();  // Local

  constructor(
    private _http: HttpClient
  ) {

  }

  getTrayXSap(periodo: string, periodoate: string, cliente: string): Observable<any> {

 //console.log("############################ inicio " );


    const endpoint = this.URL_API + 'trayxsap/list?dataDe=' + periodo + '&dataAte=' + periodoate + '&cliente=' + cliente;
      return this._http.get(endpoint, {
      headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Access-Control-Allow-Headers', 'Content-Type')
            .append('Access-Control-Allow-Credentials', 'true')
            .append('Access-Control-Allow-Methods', 'OPTIONS, HEAD, GET, PUT, POST, DELETE')
            .append('Access-Control-Allow-Origin', '*')
            .append('Access-Control-Allow-Headers',     '*')
            .append('Vary', 'Origin')
    });

  }



  getPedidosNaoIntegrados(cliente: string, maisTrinta: boolean, api: string): Observable<any> {

    const endpoint = this.URL_API + 'pednaointegrado/list?idCompany=' + cliente + '&api=' + api + '&maisTrinta=' + maisTrinta;

    return this._http.get(endpoint, {
      headers: new HttpHeaders()
                 .append('Content-Type', 'application/json')
                 .append('Access-Control-Allow-Headers', 'Content-Type')
                 .append('Access-Control-Allow-Credentials', 'true')
                 .append('Access-Control-Allow-Methods', '*')
                 .append('Access-Control-Allow-Origin', '*')
                 .append('Access-Control-Allow-Headers',     '*')
                 .append('Vary', 'Origin')
         });

       }



  getDetalhesNaoIntegrados(cliente: string, status, idlogcore: any): Observable<any> {

    const endpoint = this.URL_API + 'pednaointegrado/getRegistro?idCompany=' + cliente + '&status=' + status + '&idLogCore=' + idlogcore;
      return this._http.get(endpoint, {
                                       headers: new HttpHeaders()
                                                        .append('Content-Type', 'application/json')
                                                        .append('Access-Control-Allow-Headers', 'Content-Type')
                                                        .append('Access-Control-Allow-Credentials', 'true')
                                                        .append('Access-Control-Allow-Methods', '*')
                                                        .append('Access-Control-Allow-Origin', '*')
                                                        .append('Access-Control-Allow-Headers',     '*')
                                                        .append('Vary', 'Origin')
                                      });

  }


  getIntegracaoQtdeResumo (cliente: string, integracao: string, tempoMinutos: any, periodoDe: any, periodoAte: any ): Observable<any> {

    const endpoint = this.URL_API + 'log/qtdeResumoRegistro?idCompany=' + cliente  + '&idIntegration='  + integracao + '&tempoMinutos=' + tempoMinutos + '&dateBegin=' + periodoDe + '&dateEnd=' + periodoAte ;

    return this._http.get(endpoint, {
      headers: new HttpHeaders()
                 .append('Content-Type', 'application/json')
                 .append('Access-Control-Allow-Headers', 'Content-Type')
                 .append('Access-Control-Allow-Credentials', 'true')
                 .append('Access-Control-Allow-Methods', '*')
                 .append('Access-Control-Allow-Origin', '*')
                 .append('Access-Control-Allow-Headers',     '*')
                 .append('Vary', 'Origin')
         });

       }



  getIntegracao (cliente: string, integracao: string, tempoMinutos: any, periodoDe: any, periodoAte: any, page: any, size: any ): Observable<any> {

    const endpoint = this.URL_API + 'log/resumoNaoIntegrado?idCompany=' + cliente  + '&idIntegration='  + integracao + '&tempoMinutos=' + tempoMinutos + '&dateBegin=' + periodoDe + '&dateEnd=' + periodoAte +  '&pagina=' + page + '&qtd=' + size;

    return this._http.get(endpoint, {
      headers: new HttpHeaders()
                 .append('Content-Type', 'application/json')
                 .append('Access-Control-Allow-Headers', 'Content-Type')
                 .append('Access-Control-Allow-Credentials', 'true')
                 .append('Access-Control-Allow-Methods', '*')
                 .append('Access-Control-Allow-Origin', '*')
                 .append('Access-Control-Allow-Headers',     '*')
                 .append('Vary', 'Origin')
         });

       }



  getIntegracaoDetalhe (cliente: string, api: string, idIntegracao: string, status: string, tempoMinutos: any, periodoDe: any, periodoAte: any, page: any, size: any ): Observable<any> {

    const endpoint = this.URL_API + 'log/detalheNaoIntegrado?idCompany=' + cliente + '&api=' + api  + '&idIntegration=' + idIntegracao + '&status=' + status + '&tempoMinutos=' + tempoMinutos + '&dateBegin=' + periodoDe + '&dateEnd=' + periodoAte +  '&pagina=' + page + '&qtd=' + size;

    return this._http.get(endpoint, {
      headers: new HttpHeaders()
                 .append('Content-Type', 'application/json')
                 .append('Access-Control-Allow-Headers', 'Content-Type')
                 .append('Access-Control-Allow-Credentials', 'true')
                 .append('Access-Control-Allow-Methods', '*')
                 .append('Access-Control-Allow-Origin', '*')
                 .append('Access-Control-Allow-Headers',     '*')
                 .append('Vary', 'Origin')
         });

       }




  getIntegracaoQtdeDetalhe (cliente: string, api: string, idIntegracao: string, status: string, tempoMinutos: any, periodoDe: any, periodoAte: any): Observable<any> {

    const endpoint = this.URL_API + 'log/qtdeDetalheRegistro?idCompany=' + cliente + '&api=' + api  + '&idIntegration=' + idIntegracao + '&status=' + status + '&tempoMinutos=' + tempoMinutos + '&dateBegin=' + periodoDe + '&dateEnd=' + periodoAte;

    return this._http.get(endpoint, {
      headers: new HttpHeaders()
                 .append('Content-Type', 'application/json')
                 .append('Access-Control-Allow-Headers', 'Content-Type')
                 .append('Access-Control-Allow-Credentials', 'true')
                 .append('Access-Control-Allow-Methods', '*')
                 .append('Access-Control-Allow-Origin', '*')
                 .append('Access-Control-Allow-Headers',     '*')
                 .append('Vary', 'Origin')
         });

       }







  getPedidoStatusLogistica(cliente: string, maisTrinta: Boolean): Observable<any> {

    const endpoint = this.URL_API + 'relatorio/pedidoStatusLogistica/' + cliente + '?maisTrinta=' + maisTrinta;

    return this._http.get(endpoint, {
                                        headers: new HttpHeaders()
                                              .append('Content-Type', 'application/json')
                                              .append('Access-Control-Allow-Headers', 'Content-Type')
                                              .append('Access-Control-Allow-Credentials', 'true')
                                              .append('Access-Control-Allow-Methods', 'OPTIONS, HEAD, GET, PUT, POST, DELETE')
                                              .append('Access-Control-Allow-Origin', '*')
                                              .append('Access-Control-Allow-Headers',     '*')
                                              .append('Vary', 'Origin')
                                      });

  }
}
