import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { FormatoMensagem } from '../../../model/formatomensagem';
import { CadastrosService } from '../../../services/cadastros.service';

@Component({
  selector: 'app-detalhes-formatomensagem',
  templateUrl: 'formatomensagem-detalhes.component.html'
})
export class FormatoMensagemDetalhesComponent implements OnInit{

  @ViewChild('modalformatomensagem', {static: false}) public modalformatomensagem: ModalDirective;

  sub: boolean = false;
  _formatomensagem: FormatoMensagem = new FormatoMensagem();
  @Output() loadformatomensagem = new EventEmitter();
  titulo: string = "Novo Formato de Mensagem";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  movSub: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesFormatoMensagem.subscribe(
        (p: FormatoMensagem) => {

          if(p == null){
            this.titulo = "Novo Formato de Mensagem"
            this._formatomensagem = new FormatoMensagem();
          }else{
            this.titulo = "Editar Formato de Mensagem"
            this._formatomensagem = p;
          }

          this.limpaModal();

          this.modalformatomensagem.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  hideModal(){
    this.modalformatomensagem.hide();
    this.loadformatomensagem.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar Formato de Mensagem"){
      this.atualizar();
    }else{
      this.salvar();
    }

    form.reset();
  }

  salvar(){

    let objformatomensagem = JSON.parse(JSON.stringify(this._formatomensagem));

    this.cadastrosService.salvar("formatomensagem/insert", objformatomensagem).subscribe(
      (result) =>{

        this.toastr.success('Formato de Mensagem cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );



  }

  atualizar(){

    let objformatomensagem = JSON.parse(JSON.stringify(this._formatomensagem));

    this.cadastrosService.atualizar("formatomensagem/update", objformatomensagem, objformatomensagem.id).subscribe(
      (result) =>{

        this.toastr.success('Formato de Mensagem atualizado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );
  }
}
