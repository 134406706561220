import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {ModalService} from '../../services/modal.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {CadastrosService} from '../../services/cadastros.service';
import {RelatorioService} from '../../services/relatorio.service';
import {CrudService} from '../../services/crudService';
import {User} from '../../auth/user.model';
import {UsuarioMonitor} from '../../model/usuarioMonitor';
import * as FileSaver from 'file-saver';
import {PedStatusLogistica} from '../../model/pedidoStatusLogistica';

@Component({
  selector: 'app-pedidoscomsemremessa',
  templateUrl: './relatorio-pedidostatuslogistica.component.html',
  styleUrls: ['./relatorio-pedidostatuslogistica.component.scss']
})
export class RelatorioPedidosStatusLogisticaComponent implements OnInit {

  movSub: Subscription;
  pedidostatuslogistica: any[];
  maisTrinta
    : boolean = false;
  countComRemessaComFatura: number;
  countComRemessaSemFatura: number;
  countSemRemessa: number;
  listaPedRemessa: PedStatusLogistica[];
  usuariosmonitor: UsuarioMonitor[];
  loadingERP
    : boolean = true;
  loadingDetalhes: boolean = true;
  perfil: string = '';
  pesquisa = '';
  cliente: string;
  movSub9: any;
  opcoesRemessa: any[];
  opcoesFaturado: any[];
  remessa: string = 'Todos';
  faturado: string = 'Todos';
  todospedidostatuslogistica: any[];

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private cadastrosService: CadastrosService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private relatorioService: RelatorioService,
    private crudService: CrudService
  )
  {
    this.opcoesRemessa = [];
    this.opcoesRemessa.push({ 'id' : 'Todos'});
    this.opcoesRemessa.push({ 'id' : 'SIM'});
    this.opcoesRemessa.push({ 'id' : 'NÃO'});

    this.opcoesFaturado = [];
    this.opcoesFaturado.push({ 'id' : 'Todos'});
    this.opcoesFaturado.push({ 'id' : 'SIM'});
    this.opcoesFaturado.push({ 'id' : 'NÃO'});
  }



  exportExcel() {



    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.listaPedRemessa);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'pedidos_status_logistica');
    });
  }



  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getPerfil() {
    const user = JSON.parse(localStorage.getItem('userMonitorOmnipos'));

    const sub = this.crudService.findByGeneric('usuarios', 'id', user.id ).subscribe(res => {
      if (res.length > 0) {
        this.perfil = res[0].perfil;
      }

      this.buscar();
      sub.unsubscribe();
    });
  }

  ngOnInit() {
    this.getusuariosmonitors();
    this.getPerfil();

    console.log(this.pedidostatuslogistica);

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }
  }

  diasRegistro() {


    if (this.maisTrinta) {
      this.remessa =  "Todos";
      this.faturado = "Todos";
      alert('Registros superiores à trinta dias podem demorar mais para carregar');
      this.buscar();
    } else {

      this.remessa =  "Todos";
      this.faturado = "Todos";
      this.buscar();

    }

  }

  buscar() {

      const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
      for (const u of this.usuariosmonitor) {
        if (u.email === user.email) {
          this.cliente = u.cliente;
        }
      }

      this.loadingERP = true;
      this.loadingDetalhes = false;

      this.pedidostatuslogistica = [];

        this.spinner.show();
      // tslint:disable-next-line:max-line-length
        const sub = this.relatorioService.getPedidoStatusLogistica(this.cliente, this.maisTrinta).subscribe(data => {

          this.extracted(data);
          this.listaFinal();

          sub.unsubscribe();
        }, error => {
          this.loadingERP = false;
          console.log(error);
          this.spinner.hide();
        });


  }

  listaFinal() {

    this.listaPedRemessa = [];

     for (const pedL of this.pedidostatuslogistica) {

       const pedlog = new PedStatusLogistica();

       pedlog.Pedido = pedL.pedido;
       pedlog.Faturado = pedL.statusFaturado;
       pedlog.Remessa = pedL.geradoRemessa;

       this.listaPedRemessa.push(pedlog);


     }

    this.listaPedRemessa.sort((a, b) => (a.Pedido > b.Pedido) ? -1 : ((b.Pedido > a.Pedido) ? 1 : 0));

  }

  pesquisar(tableMov, texto) {

    tableMov.filterGlobal(texto, 'contains');

  }


  async extracted(data) {

    if (data && data.length > 0) {

      this.pedidostatuslogistica = data;
      this.todospedidostatuslogistica = data;
      this.pedidostatuslogistica.sort((a, b) => (a.pedido > b.pedido) ? -1 : ((b.pedido > a.pedido) ? 1 : 0));
      this.todospedidostatuslogistica.sort((a, b) => (a.pedido > b.pedido) ? -1 : ((b.pedido > a.pedido) ? 1 : 0));
      this.carregaCount();

      await this.spinner.hide();
      this.loadingERP = false;

    } else {
      this.toastr.success('Todos os pedidos estão integrados!', 'ATENÇÃO');
      this.pedidostatuslogistica = [];
      await this.spinner.hide();
      this.loadingERP = false;
    }

  }

  async getusuariosmonitors() {

    this.usuariosmonitor = [];

    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed) {

      this.spinner.show();

      this.movSub = this.crudService.listarByFiltro('usuarios', null).subscribe(
        (result: any) => {

          this.usuariosmonitor = result;

          this.spinner.hide();

        }, error => {
          console.log(error);
          this.spinner.hide();
        });

    }


  }

  onChangeFaturado(e) {

    this.faturado = e;

    this.filterDados();

    this.listaFinal();
  }

  carregaCount() {
    let temp_pedidostatuslogistica = this.todospedidostatuslogistica.filter(function (el) {
        return (el.geradoRemessa === 'NÃO');
      }
    );

    this.countSemRemessa = temp_pedidostatuslogistica.length;

    temp_pedidostatuslogistica = this.todospedidostatuslogistica.filter(function (el) {
        return (el.geradoRemessa === 'SIM' && el.statusFaturado === 'NÃO');
      }
    );

    this.countComRemessaSemFatura = temp_pedidostatuslogistica.length;

    temp_pedidostatuslogistica = this.todospedidostatuslogistica.filter(function (el) {
        return (el.geradoRemessa === 'SIM' && el.statusFaturado === 'SIM');
      }
    );

    this.countComRemessaComFatura = temp_pedidostatuslogistica.length;
  }

  filtrar(op: number) {
    if (op === 1) {
      this.remessa = 'NÃO';
      this.faturado = 'Todos';
    }

    if (op === 2) {
      this.remessa = 'SIM';
      this.faturado = 'NÃO';
    }

    if (op === 3) {
      this.remessa = 'SIM';
      this.faturado = 'SIM';
    }

    this.filterDados();
  }

  filterDados() {

    this.pedidostatuslogistica = [];

    if (this.remessa === 'SIM') {
      this.pedidostatuslogistica = this.todospedidostatuslogistica.filter(function (el) {
          return (el.geradoRemessa === 'SIM');
        }
      );
    } else if (this.remessa === 'NÃO') {
      this.pedidostatuslogistica = this.todospedidostatuslogistica.filter(function (el) {
          return (el.geradoRemessa === 'NÃO');
        }
      );
    } else {
      this.pedidostatuslogistica = this.todospedidostatuslogistica.filter(function (el) {
          return (el.geradoRemessa === 'NÃO' || el.geradoRemessa === 'SIM');
        }
      );
    }

    if (this.faturado === 'SIM') {
      this.pedidostatuslogistica = this.pedidostatuslogistica.filter(function (el) {
          return (el.statusFaturado === 'SIM');
        }
      );
    } else if (this.faturado === 'NÃO') {
      this.pedidostatuslogistica = this.pedidostatuslogistica.filter(function (el) {
          return (el.statusFaturado === 'NÃO');
        }
      );
    } else {
      this.pedidostatuslogistica = this.pedidostatuslogistica.filter(function (el) {
          return (el.statusFaturado === 'NÃO' || el.statusFaturado === 'SIM');
        }
      );
    }
  }

  onChangeRemessa(e) {
    this.remessa = e;

    this.filterDados();

    this.listaFinal();
  }

}


