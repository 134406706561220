// Angular
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

// Dropdowns Component
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

// Components Routing
import { ComponentsRoutingModule } from './components-routing.module';
import { TableModule } from 'primeng/table';
import { StepsModule } from 'primeng/steps';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from '../auth/auth-interceptor.service';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxDropzoneModule } from 'ngx-dropzone';
import DirectivesModule from '../directives/directives.module';
import { EndpointComponent } from './endpoint/endpoint.component';
import { EndpointDetalhesComponent } from './endpoint/endpoint-detalhes/endpoint-detalhes.component';
import { ClienteComponent } from './cliente/cliente.component';
import { ClienteDetalhesComponent } from './cliente/cliente-detalhes/cliente.detalhes.component';
import { AmbienteDetalhesComponent } from './ambiente/ambiente-detalhes/ambiente-detalhes.component';
import { AmbienteComponent } from './ambiente/ambiente.component';
import { ConfPainelDetalhesComponent } from './confpainel/confpainel-detalhes/confpainel-detalhes.component';
import { ConfPainelComponent } from './confpainel/confpainel.component';
import { FormatoMensagemComponent } from './formatomensagem/formatomensagem.component';
import { FormatoMensagemDetalhesComponent } from './formatomensagem/formatomensagem-detalhes/formatomensagem-detalhes.component';
import { TipoInterfaceComponent } from './tipointerface/tipointerface.component';
import { TipoInterfaceDetalhesComponent } from './tipointerface/tipointerface-detalhes/tipointerface-detalhes.component';
import { IntegracaoComponent } from './integracao/integracao.component';
import { IntegracaoDetalhesComponent } from './integracao/integracao-detalhes/integracao-detalhes.component';
import { ModeloMediadorComponent } from './modelomediador/modelomediador.component';
import { ModeloMediadorDetalhesComponent } from './modelomediador/modelomediador-detalhes/modelomediador-detalhes.component';
import { TipoComunicacaoComponent } from './tipocomunicacao/tipocomunicacao.component';
import { TipoComunicacaoDetalhesComponent } from './tipocomunicacao/tipocomunicacao-detalhes/tipocomunicacao-detalhes.component';
import { TipoMediadorComponent } from './tipomediador/tipomediador.component';
import { TipoMediadorDetalhesComponent } from './tipomediador/tipomediador-detalhes/tipomediador-detalhes.component';
import { TipoProcessamentoComponent } from './tipoprocessamento/tipoprocessamento.component';
import { TipoProcessamentoDetalhesComponent } from './tipoprocessamento/tipoprocessamento-detalhes/tipoprocessamento-detalhes.component';
import { RoteamentoComponent } from './roteamento/roteamento.component';
import { RoteamentoDetalhesComponent } from './roteamento/roteamento-detalhes/roteamento-detalhes.component';
import { LogDetalhesComponent } from './log/log-detalhes/log-detalhes.component';
import {RelatorioIntegracaoComponent} from './relatorio-integracao/relatorio-integracao.component';
import { RelatorioIntegracaoDetalhesComponent } from './relatorio-integracao/relatorio-integracao-detalhes/relatorio-integracao-detalhes.component';
import { LoginDetalhesComponent } from '../views/login/login-detalhes/login-detalhes.component';
import { LogComponent } from './log/log.component';
import { UsuariosmonitorDetalhesComponent } from './usuariosmonitor/usuariosmonitor-detalhes/usuariosmonitor.detalhes.component';
import { UsuariosMonitorComponent } from './usuariosmonitor/usuariosmonitor.component';
import { UsuariosApiComponent } from './usuariosapi/usuariosapi.component';
import { UsuariosApiDetalhesComponent } from './usuariosapi/usuariosapi-detalhes/usuariosapi.detalhes.component';
import { DeParaGenericDetalhesComponent } from './deparageneric/deparageneric-detalhes/deparageneric-detalhes.component';
import { DeParaGenericComponent } from './deparageneric/deparageneric.component';
import { PassosRoteamentoComponent } from './passosroteamento/passosroteamento.component';
import { PassosRoteamentoDetalhesComponent } from './passosroteamento/passosroteamento-detalhes/passosroteamento-detalhes.component';
import { TaxasAdministrativasComponent } from './taxas-adminitrativas/taxasadministrativas.component';
import { TaxasAdministrativasDetalhesComponent } from './taxas-adminitrativas/taxasadminitrativas.detalhes/taxasadministrativas-detalhes.component';
import { ConciliacaoComponent } from './conciliacao/conciliacao.component';
import {HistoricoConciliacaoComponent} from './historico-conciliacao/hist-conciliacao.component';
import {RelatorioTrayXSAPComponent} from './relatorio-trayxsap/relatorio-trayxsap.component';
import {AngularBasicModalModule} from 'angular-basic-modal';
import {RelatorioPedidosNaoIntegradosComponent} from './relatorio-pedidosnaointegrados/relatorio-pedidosnaointegrados.component';
import {RelatorioPedidosStatusLogisticaComponent} from './relatorio-pedidostatuslogistica/relatorio-pedidostatuslogistica.component';
import {ConciliacaoModalComponent} from './conciliacao/conciliacao-modal/conciliacao-modal.component';
import { TabsModule } from 'ngx-bootstrap';
import {LancamentoModalComponent} from './conciliacao/lancamento-modal/lancamento-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ComponentsRoutingModule,
    BsDropdownModule.forRoot(),
    TableModule,
    StepsModule,
    NgxSpinnerModule,
    ModalModule.forRoot(),
    AngularFireAuthModule,
    NgxDropzoneModule,
    DirectivesModule,
    AngularBasicModalModule,
    TabsModule,
  ],
  declarations: [
    EndpointComponent,
    EndpointDetalhesComponent,
    ClienteComponent,
    ClienteDetalhesComponent,
    AmbienteComponent,
    AmbienteDetalhesComponent,
    ConfPainelComponent,
    ConfPainelDetalhesComponent,
    FormatoMensagemComponent,
    FormatoMensagemDetalhesComponent,
    TipoInterfaceComponent,
    TipoInterfaceDetalhesComponent,
    IntegracaoComponent,
    IntegracaoDetalhesComponent,
    ModeloMediadorComponent,
    ModeloMediadorDetalhesComponent,
    TipoComunicacaoComponent,
    TipoComunicacaoDetalhesComponent,
    TipoMediadorComponent,
    TipoMediadorDetalhesComponent,
    TipoProcessamentoComponent,
    TipoProcessamentoDetalhesComponent,
    RoteamentoComponent,
    RoteamentoDetalhesComponent,
    LoginDetalhesComponent,
    LogDetalhesComponent,
    LogComponent,
    RelatorioIntegracaoDetalhesComponent,
    RelatorioIntegracaoComponent,
    UsuariosMonitorComponent,
    UsuariosmonitorDetalhesComponent,
    UsuariosApiComponent,
    UsuariosApiDetalhesComponent,
    DeParaGenericComponent,
    DeParaGenericDetalhesComponent,
    PassosRoteamentoComponent,
    PassosRoteamentoDetalhesComponent,
    TaxasAdministrativasComponent,
    TaxasAdministrativasDetalhesComponent,
    ConciliacaoComponent,
    HistoricoConciliacaoComponent,
    RelatorioTrayXSAPComponent,
    RelatorioPedidosNaoIntegradosComponent,
    RelatorioPedidosStatusLogisticaComponent,
    ConciliacaoModalComponent,
    LancamentoModalComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    }
  ],
})
export class ComponentsModule { }
