import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import {ModalService} from '../../../services/modal.service';
import * as $ from 'jquery';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-lancamento-modal',
  templateUrl: 'lancamento-modal.component.html'
})
export class LancamentoModalComponent implements OnInit {

  @ViewChild('modallancamento', {static: false}) public modalLancamento: ModalDirective;

  sub: boolean = false;
  @Output() finalizaProcessamento = new EventEmitter();
  titulo: string = 'Processar Lancamento';
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  justificativa: string = '';
  precisaJustificativa: boolean = false;
  registros: any[];
  qtdRegistros: number;

  constructor(
    private modalService: ModalService) {
  }

  ngOnInit() {

    if (this.sub === false) {

      this.sub = true;

      this.modalService.modalLancamento.subscribe(
        (reg: any[]) => {

          this.justificativa = '';
          this.registros = reg;
          this.qtdRegistros = this.registros.length;
          this.limpaModal();

          this.modalLancamento.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  hideModal() {
    this.modalLancamento.hide();
  }

  confirmar() {

    const obj = {
      'registros': this.registros
    };

    this.modalService.modalLancamentoRetorno.emit(obj);
    this.modalLancamento.hide();
    
  }

  limpaModal() {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

}
