import {Component, OnDestroy, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { ModalService } from '../services/modal.service';
import { AuthService } from './auth.service';
import { FirebaseServiceProvider } from '../providers/firebase-service';
import { ClientesService } from '../services/clientes.service';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { CadastrosService } from '../services/cadastros.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: '../views/login/login.component.html'
})
export class AuthComponent implements OnInit {


   isLoading = false;
  error: string = null;
  movSub: Subscription;

  constructor(
      private authService: AuthService,
      private modalService: ModalService,
      private route: ActivatedRoute,
      private router: Router,
      private fbService: FirebaseServiceProvider
  ) {

     if (this.authService.autoLogin()) {
        this.isLoading = true;
        this.router.navigate(['/dashboard']);
     } else {
        this.fbService.init('global');
     }
  }

ngOnInit() {
    console.log ( "INIT DE AUTH COMPONENT");
  }


  onSubmit(form: NgForm) {

    this.error = null;

    if (!form.valid) {
      return;
    }

    const email = form.value.email;
    const password = form.value.password;

    this.isLoading = true;

    this.efetuaLogin(email, password);

    form.reset();
  }

  efetuaLogin(email, password) {
    this.authService.login(email, password)
      .then(
        (result) => {

          this.isLoading = false;

          if (result.user.emailVerified || true) {
            this.authService.handleAuthentication(result.user.email, result.user.uid, result.user.refreshToken, 3600);

            if (this.movSub && this.movSub.closed === false) {
              this.movSub.unsubscribe();
            }

            if (!this.movSub || this.movSub.closed) {

              this.router.navigate(['/dashboard']);

            }

          }

        },
        error => {
          console.log(error);
          this.error = this.authService.handleError(error.code);
          this.isLoading = false;
        }
      );
  }

  ngOnDestroy() {
    if (this.movSub && this.movSub.closed == false) {
      this.movSub.unsubscribe();
    }
  }

   async getlogin(){
     console.log ( 'Chamou o getlogin');

   }


    esqueciMinhaSenha( ) {
        console.log ( 'Chamou o Esqueci minha Senha');
           console.log ( 'Chamou o emit em  Esqueci minha Senha');
                if (  this.modalService.modalDetalhesLogin != null ) {
                    console.log ( "modalDetalhesLogin não é NULL" ) ;
                    console.log (  this.modalService.modalDetalhesLogin) ;
                } else {
                    console.log ( "modalDetalhesLogin EH NULL" ) ;

                }



        this.modalService.modalDetalhesLogin.emit(null );
         console.log ( 'FINALIZOU o Esqueci minha Senha');
      }


}
