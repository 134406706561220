import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {ModalService} from '../../services/modal.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {CadastrosService} from '../../services/cadastros.service';
import {OrigemLancamentosEnum} from '../../model/enumeration/origemLancamentosEnum';
import {RelatorioService} from '../../services/relatorio.service';
import {CrudService} from '../../services/crudService';
import {User} from '../../auth/user.model';
import {UsuarioMonitor} from '../../model/usuarioMonitor';
import * as FileSaver from 'file-saver';
import {BaseModal, BaseModalConfig, BasicModalService} from 'angular-basic-modal/index';
import { analytics } from 'firebase';

@Component({
  selector: 'app-pedidosnaointegrados',
  templateUrl: './relatorio-pedidosnaointegrados.component.html',
  styleUrls: ['./relatorio-pedidosnaointegrados.component.scss']
})
export class RelatorioPedidosNaoIntegradosComponent implements OnInit {

  movSub: Subscription;
  pedidosnaointegrados: any[];
  detalhenaointegrados: any[];
  usuariosmonitor: UsuarioMonitor[];
  maisTrinta
   : boolean =  false;
  loadingERP
    : boolean = true;
  ehTray
    : boolean =  false;
  loadingDetalhes: boolean = true;
  perfil: string = '';
  pesquisa = '';
  cliente: string;
  movSub9: any;
  strApi: string;

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private cadastrosService: CadastrosService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private relatorioService: RelatorioService,
    private crudService: CrudService
  ) {

  }

  exportExcel() {

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.pedidosnaointegrados);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'pedidos_nao_integrados');
    });
  }

  exportExcelDetalhe() {

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.detalhenaointegrados);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'detalhes_nao_integrados');
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getPerfil() {
    const user = JSON.parse(localStorage.getItem('userMonitorOmnipos'));

    const sub = this.crudService.findByGeneric('usuarios', 'id', user.id ).subscribe(res => {
      if (res.length > 0) {
        this.perfil = res[0].perfil;
      }

      this.buscar();
      sub.unsubscribe();
    });
  }

  ngOnInit() {
    this.getusuariosmonitors();
    this.getPerfil();

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }
  }

  diasRegistro() {

    if(this.maisTrinta) {

      alert('Registros superiores à trinta dias podem demorar mais para carregar');

      this.buscar();

    } else {

      this.buscar();

    }

  }

  buscar() {

      const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
      for (const u of this.usuariosmonitor) {
        if (u.email === user.email) {
          this.cliente = u.cliente;
        }
      }

      this.loadingERP = true;
      this.loadingDetalhes = false;

      this.pedidosnaointegrados = [];

        this.spinner.show();
      // tslint:disable-next-line:max-line-length

      if ( this.ehTray  ) {
        this.strApi = 'TRAY';
      } else {
        this.strApi = 'FIELDY';
      }
        const sub = this.relatorioService.getPedidosNaoIntegrados(this.cliente,  this.maisTrinta, this.strApi).subscribe(data => {

          this.extracted(data);

          sub.unsubscribe();
        }, error => {
          this.loadingERP = false;
          console.log(error);
          this.spinner.hide();
        });


  }

  detalhe(resumo: any){

    const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
    for (const u of this.usuariosmonitor) {
      if (u.email === user.email) {
        this.cliente = u.cliente;
      }
    }

    this.loadingDetalhes = true;
    this.loadingERP = false;

    this.detalhenaointegrados = [];

    this.spinner.show();
    // tslint:disable-next-line:max-line-length
    const sub = this.relatorioService.getDetalhesNaoIntegrados(this.cliente, resumo.status, resumo.idlogcore).subscribe(async data => {

      if (data && data.length > 0) {

        this.detalhenaointegrados = data;
        console.log(data);

        await this.spinner.hide();
        this.loadingDetalhes = false;

      } else {
        this.toastr.warning('Não foi localizado nenhum registro de detalhe!', 'ATENÇÃO');
        this.detalhenaointegrados = [];
        await this.spinner.hide();
        this.loadingDetalhes = false;
      }

      sub.unsubscribe();
    }, error => {
      this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
      console.log(error);
      this.spinner.hide();
    });

  }

  pesquisar(tableMov, texto) {

    tableMov.filterGlobal(texto, 'contains');

  }


  async extracted(data) {

    if (data && data.length > 0) {

      this.pedidosnaointegrados = data;
      await this.spinner.hide();
      this.loadingERP = false;

    } else {
      this.toastr.success('Todos os pedidos estão integrados!', 'ATENÇÃO');
      this.pedidosnaointegrados = [];
      await this.spinner.hide();
      this.loadingERP = false;
    }

  }

  async getusuariosmonitors() {

    this.usuariosmonitor = [];

    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed) {

      this.spinner.show();

      this.movSub = this.crudService.listarByFiltro('usuarios', null).subscribe(
        (result: any) => {

          this.usuariosmonitor = result;
          console.log(result);

          this.spinner.hide();

        }, error => {
          console.log(error);
          this.spinner.hide();
        });

    }


  }


}


