import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { CadastrosService } from '../../services/cadastros.service';
import { CrudService } from '../../services/crudService';
import { UsuarioMonitor } from '../../model/usuarioMonitor';

@Component({
  selector: 'app-usuariosmonitor',
  templateUrl: './usuariosmonitor.component.html',
  styleUrls: ['./usuariosmonitor.component.scss']
})
export class UsuariosMonitorComponent implements OnInit {

  movSub: Subscription;
  usuariosmonitor: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private modalService: ModalService,
      private cadastrosService: CadastrosService,
      private crudService: CrudService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){

  }

  ngOnInit() {
    this.getusuariosmonitors();
  }

  async getusuariosmonitors(){

    this.usuariosmonitor = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();

      this.movSub = this.crudService.listarByFiltro("usuarios", null).subscribe(
        (result: any) =>{

          this.usuariosmonitor =  result;
          console.log(result);

          this.spinner.hide();

       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }


  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novousuariosmonitor(){
    this.modalService.modalDetalhesUsuariosMonitor.emit(null);
  }

  editar(usuariosmonitor: UsuarioMonitor){
    this.modalService.modalDetalhesUsuariosMonitor.emit(usuariosmonitor);
  }

  excluir(usuariosmonitor: UsuarioMonitor){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{

        if(confirmed){
          this.cadastrosService.excluir("user/delete", usuariosmonitor).subscribe(
            (result) =>{

              this.toastr.success('Usuario Monitor excluído com sucesso!', 'Sucesso!');
              this.getusuariosmonitors();
              console.log(result);

            },
            error=> {

              this.toastr.error(error.message, 'Erro!');
              console.log(error);

            }
          );
        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }


}
