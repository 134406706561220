
export class Integracao {

    public id: string;
    public id_cliente: string;
    public id_tipo: string;
    public descricao: string;

    constructor(){

    }
}
