import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsuarioMonitor } from '../../../model/usuarioMonitor';
import { CadastrosService } from '../../../services/cadastros.service';

@Component({
  selector: 'app-detalhes-login',
  templateUrl: 'login-detalhes.component.html'
})
export class LoginDetalhesComponent implements OnInit{

  @ViewChild('modallogin', {static: false}) public modallogin: ModalDirective;

  sub: boolean = false;
  _usuarioMonitor: UsuarioMonitor = new UsuarioMonitor();
  @Output() loadlogin = new EventEmitter();
  titulo: string = "Esqueci minha senha";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  movSub: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }

  ngOnInit() {
      console.log ( '##################### Chamou INIT de login detalhes ################# ');

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesLogin.subscribe(
        (p: UsuarioMonitor) => {

          if(p == null){
            this.titulo = "Esqueci minha senha"
            this._usuarioMonitor = new UsuarioMonitor();
          }else{
            this.titulo = "Esqueci minha senha"
            this._usuarioMonitor = p;
          }

          this.limpaModal();

          this.modallogin.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  hideModal(){
    this.modallogin.hide();
    this.loadlogin.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }
   this.salvar();

    form.reset();
  }

  salvar(){

    let objlogin = JSON.parse(JSON.stringify(this._usuarioMonitor));

    this.cadastrosService.salvar("tipointerface/insert", objlogin).subscribe(
      (result) =>{

        this.toastr.success('xxxxxxxxxxxxTipo de Interface cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );



  }

}
