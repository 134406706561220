import { Component, OnDestroy, Inject, OnInit, AfterContentChecked } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {navItems, navItemsAdmin, navItemsAdminConciliacao, navItemsOperConciliacao} from '../../_nav';
import { AuthService } from '../../auth/auth.service';
import { CrudService } from '../../services/crudService';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements AfterContentChecked, OnInit, OnDestroy {

  public navItems = navItems;

  public sidebarMinimized = true;
  public minimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;

  usuarioLogado: any;
  mostraMenu: boolean = true;
  mostraMenuAside: boolean = false;
  private currentRoute: any;

  constructor(
    @Inject(DOCUMENT) _document?: any,
    private authService?: AuthService,
    private crudService?: CrudService,
    private router?: Router) {

    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });
    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

  }

  ngOnInit() {
    this.isAdmin();

  }

  isAdmin() {
    const user = JSON.parse(localStorage.getItem('userMonitorOmnipos'));

    const sub = this.crudService.findByGeneric('usuarios', 'id', user.id ).subscribe(res => {
      if (res.length > 0) {
        if (res[0].perfil === 'ADMINISTRADOR') {
          this.navItems = navItemsAdmin;
        } else  if (res[0].perfil === 'ADMNISTRADOR CONCILIAÇÃO') {
          this.navItems = navItemsAdminConciliacao;
        } else if (res[0].perfil === 'OPERADOR CONCILIAÇÃO') {
          this.navItems = navItemsOperConciliacao;
        } else {
          this.navItems = navItems;
        }
      } else {
        this.navItems = navItems;
      }
      sub.unsubscribe();
    });
  }

  ngAfterContentChecked(){
    this.currentRoute = this.router.url;

    if (this.currentRoute.toLowerCase().indexOf('conciliacao') !== -1) {
      this.minimized = true;
    } else{
      this.minimized = false;
      this.mostraMenuAside = false;
    }
  }

  ngOnDestroy(): void {

    this.changes.disconnect();
  }

  onLogout() {

    this.authService.logout();
  }



}
