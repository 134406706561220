import { AdquirenteEnum } from "./enumeration/adquirenteEnum";
import { BandeiraEnum } from "./enumeration/bandeiraEnum";
import { CondicaoPagamentoEnum } from "./enumeration/condicaoPagamentoEnum";
import { OperacaoEnum } from "./enumeration/operacaoEnum";

export class TaxasAdministrativas {

    public id: number;
    public id_cliente: string;
    public operacao: OperacaoEnum;
    public bandeira: BandeiraEnum;
    public adquirente: AdquirenteEnum;
    public condicaoPagamento: CondicaoPagamentoEnum;
    public parcelas: number;
    public dataInicial: string;
    public dataFim: string;
    public idOperadora: string;
    public idBanco: string;
    public idImposto: string;
    public valorTaxa: number;
    public valorTaxaFixo: number;
    public idAjuste: string;
    public valorDifAjuste: number;

    constructor(){

    }
}
