import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import { ModalService } from '../../services/modal.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {CadastrosService} from '../../services/cadastros.service';
import {RelatorioService} from '../../services/relatorio.service';
import {CrudService} from '../../services/crudService';
import {User} from '../../auth/user.model';
import {UsuarioMonitor} from '../../model/usuarioMonitor';
import {BaseModal, BaseModalConfig, BasicModalService} from 'angular-basic-modal/index';
import { analytics } from 'firebase';
import { LazyLoadEvent } from 'primeng/primeng';
import { Table } from 'primeng/table';

import * as FileSaver from 'file-saver';




@Component({
  selector: 'app-relatorio-integracao',
  templateUrl: './relatorio-integracao.component.html',
  styleUrls: ['./relatorio-integracao.component.scss']
})
export class RelatorioIntegracaoComponent implements OnInit {
    @ViewChild('tableDetalhe', { static: false }) tableDet: Table;

  movSub: Subscription;
  movSub1: Subscription;
  movSub2: Subscription;

  totalDataResumoLength: number;
  totalDataDetalheLength: number;
  integracoes: any[];
  integracoesExport: any[];
  cmbIntegracao: any[];
  cmbIntegracaoSel: string;
  detalheintegracao: any[];
  detalheintegracaoExport: any[];
  usuariosmonitor: UsuarioMonitor[];
  maisTrinta : boolean =  false;
  loadingERP : boolean = true;
  loadingDetalhes: boolean = true;
  perfil: string = '';
  pesquisa = '';
  pesquisaDet = '';
  cliente:  string;
  tempoMinutos : string = '60';
  movSub9: any;
  clientes: any[];
  resumo: any;
  periodoDe: string;
  periodoAte: string;
    data: any;
    options: any;
  page: any = 1;
  pageDetalhe: any = 1;
  sizeResumo: any = 10;
  sizeDetalhe: any = 10;
  carregando: boolean = false;
  carregandoDetalhe: boolean = false;
  fezBusca: boolean = false;
  fezBuscaDetalhe: boolean = false;

  movSubUsuario: Subscription;
  usuarioLogado: any;
  paginaBusca: any = 0;
  sizeBusca: any = 0;






  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private cadastrosService: CadastrosService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private relatorioService: RelatorioService,
    private crudService: CrudService
  ) {

  }


  ngOnInit() {

    this.getusuariosmonitors();

    this.getClientes();
    this.getintegracaos();


  }


  async getusuariosmonitors() {

    this.usuariosmonitor = [];

    if (this.movSubUsuario && this.movSubUsuario.closed === false) {
      this.movSubUsuario.unsubscribe();
    }

    if (!this.movSubUsuario || this.movSubUsuario.closed) {

      this.movSubUsuario = this.crudService.listarByFiltro('usuarios', null).subscribe(
        (result: any) => {

          this.usuariosmonitor =  result;

          const user = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
          for (const u of this.usuariosmonitor) {
            if (u.id === user.id) {
                this.usuarioLogado = u;
            }
          }



       }, error => {
          this.spinner.hide();
            console.log(error);
       });

    }


  }



  onChangeDateDe(e) {
    this.tempoMinutos = '';
     this.periodoDe = e;
  }



  onChangeDateAte(e) {
    this.tempoMinutos = '';
     this.periodoAte = e;
  }

  onChangeTempoMinutos(e) {
    this.tempoMinutos= e;
    this.periodoDe = '';
    this.periodoAte = '';
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }
  }


    exportExcelResumo() {
        this.carregaResumo (true);
    }


    importExcellResumo() {

       import('xlsx').then(xlsx => {

          const worksheet = xlsx.utils.json_to_sheet(this.integracoesExport);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveAsExcelFile(excelBuffer, 'resumo_alerta_integracao');

        });


    }



    exportExcelDetalhe() {
        this.detalhe (true);
    }

  importExcelDetalhe() {

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.detalheintegracaoExport);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'detalhe_alerta_integracao');
    });
  }


  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

copyMessage(text: string) {
   navigator.clipboard.writeText(text).then().catch(e => console.log(e));
}

  async getClientes() {

    this.clientes = [];

    if (this.movSub1 && this.movSub1.closed === false) {
      this.movSub1.unsubscribe();
    }

    if (!this.movSub1 || this.movSub1.closed) {

      this.movSub1 = this.cadastrosService.listar('cliente/list').subscribe(
        (result: any) => {


            if (this.usuarioLogado.perfil === 'ADMINISTRADOR') {

          for (const cli of result) {
                        if (cli.tipo === 'CLIENTE') {
                          this.clientes.push(cli);
                        }
                      }
                      this.clientes.push({ 'id' : 'Todos', 'nome' : 'Todos'});
            } else {
            this.clientes.push({ 'id' : this.usuarioLogado.cliente, 'nome' : this.usuarioLogado.cliente});
            this.cliente = this.usuarioLogado.cliente;
            }




            this.getintegracaos();


       }, error => {
            console.log(error);
            this.spinner.hide();
       });

    }

  }
  buscar() {
        this.carregando = true;
        this.fezBusca = true;
        this.fezBuscaDetalhe = false;
         this.detalheintegracao = [];
        this.carregaResumo (false);
  }



  async carregaResumo( exportacao: boolean ) {


    if ( this.carregando === false  ) {
        return;
    }
     this.spinner.show();

    if ( exportacao === false ) {
        this.carregando  = true;
        this.loadingERP = true;
        this.loadingDetalhes = false;
        this.integracoes = [];



        const subQtde = this.relatorioService.getIntegracaoQtdeResumo(this.cliente, this.cmbIntegracaoSel, this.tempoMinutos, this.periodoDe, this.periodoAte  ).subscribe(data => {



           this.totalDataResumoLength  = data.qtde;


          subQtde.unsubscribe();
        }, error => {
          this.loadingERP = false;
          console.log(error);
          this.spinner.hide();
        });


    }

        this.paginaBusca = this.page;
        this.sizeBusca = this.sizeResumo;
        if ( exportacao ) {
            this.paginaBusca = 0;
            this.sizeBusca= 0
         }

         const sub =    this.relatorioService.getIntegracao(this.cliente, this.cmbIntegracaoSel, this.tempoMinutos, this.periodoDe, this.periodoAte, this.paginaBusca, this.sizeBusca ).subscribe( data => {
           this.extracted(data, exportacao);
            if ( exportacao ) {
                this.importExcellResumo();
            }
          sub.unsubscribe();
        }, error => {
          this.loadingERP = false;
          console.log(error);
          this.spinner.hide();
        });


  }


  changeClientes() {
    this.getintegracaos();
  }

 exibeDetalhe ( resumo: any ) {
    this.carregandoDetalhe = true;
    this.fezBuscaDetalhe = true;
    this.resumo = resumo;
    this.detalhe(false);
 }


  detalhe( exportacao : boolean ){
    if ( this.carregandoDetalhe === false  ) {
           return;
    }
    this.spinner.show();
     if ( exportacao === false ) {
    this.carregandoDetalhe  = true;


    this.loadingDetalhes = true;
    this.loadingERP = false;

    this.detalheintegracao = [];

    // tslint:disable-next-line:max-line-length



     const subQtdeDetalhe = this.relatorioService.getIntegracaoQtdeDetalhe( this.resumo.cliente, this.resumo.api, this.resumo.idIntegracao, this.resumo.status, this.tempoMinutos, this.periodoDe, this.periodoAte ).subscribe(data => {



           this.totalDataDetalheLength  = data.qtde;


          subQtdeDetalhe.unsubscribe();
        }, error => {
          this.loadingERP = false;
          console.log(error);
          this.spinner.hide();
        });
    }
        this.paginaBusca = this.pageDetalhe;
        this.sizeBusca = this.sizeDetalhe;
        if ( exportacao ) {
            this.paginaBusca = 0;
            this.sizeBusca= 0
         }
    const subDetalhe = this.relatorioService.getIntegracaoDetalhe( this.resumo.cliente, this.resumo.api, this.resumo.idIntegracao, this.resumo.status, this.tempoMinutos, this.periodoDe, this.periodoAte, this.paginaBusca, this.sizeBusca  ).subscribe( async  data => {

      if (data && data.length > 0) {
        if ( exportacao ) {
            this.detalheintegracaoExport = data;
            this.importExcelDetalhe() ;
        } else {
            this.detalheintegracao = data;
        }
         this.spinner.hide();
        this.loadingDetalhes = false;

      } else {
        this.toastr.warning('Não foi localizado nenhum registro de detalhe!', 'ATENÇÃO');
        this.detalheintegracao = [];
         this.spinner.hide();
        this.loadingDetalhes = false;
      }

      subDetalhe.unsubscribe();
    }, error => {
      this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
      console.log(error);
      this.spinner.hide();
    });

  }

  async getintegracaos() {
     this.cmbIntegracao = [];

    if (this.movSub2 && this.movSub2.closed === false) {
      this.movSub2.unsubscribe();
    }

     if ((!this.movSub2 || this.movSub2.closed)  ) {

      if (  this.cliente  != undefined ) {
         this.movSub2 = this.cadastrosService.listar('integracao/list?idCompany=' + this.cliente ).subscribe(
         (result: any) => {

            this.cmbIntegracao =  result;
            this.cmbIntegracao.push({ 'id' : 'Todos', 'descricao': 'Todos'});



       }, error => {
            console.log(error);
            this.spinner.hide();
       });
       }
     this.cmbIntegracao.push({ 'id' : 'Todos', 'descricao': 'Todos'});

    }


  }
  pesquisar(tableMov, texto) {

    tableMov.filterGlobal(texto, 'contains');

  }


   extracted(data, exportacao : boolean ) {

    if (data && data.length > 0) {

      if ( exportacao ) {
            this.integracoesExport = data;
      } else {
        this.integracoes = data;
        this.loadingERP = false;
      }
       this.spinner.hide();

    } else {
      this.toastr.success('Sem alertas de integração!', 'ATENÇÃO');
      this.integracoes = [];
       this.spinner.hide();
      this.loadingERP = false;
    }


  }



  detalheModal(relatorioDetalhe: any) {
      this.modalService.modalDetalhesRelatorioIntegracao.emit(relatorioDetalhe);
  }

  loadDataLazyResumo(event: LazyLoadEvent): void {
    if ( this.fezBusca === false ) {
        return;
    }
    const first = event.first;
    const rows = event.rows;

    this.page = (first / rows)+1;
    this.sizeResumo = rows;

    this.carregando = true;
    this.carregaResumo(false);
  }

  loadDataLazyDetalhe(event: LazyLoadEvent): void {
      if ( this.fezBuscaDetalhe === false ) {
          return;
      }
     const first = event.first;
    const rows = event.rows;

    this.pageDetalhe = (first / rows)+1;
    this.sizeDetalhe = rows;

    this.detalhe( false );
  }

}


