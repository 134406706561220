import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Filtro } from '../model/filtro';
import {RequestOptions} from "@angular/http";


@Injectable({
  providedIn: 'root'
})
export class ConciliacaoService {

  private URL_API = environment.APIEndpoint.valueOf();  // Local
  private URL_APIS3 = environment.S3Endpoint.valueOf();  //Local


  constructor(
    private _http: HttpClient
  ) {

  }

  postFile(periodo: string, periodoate: string, cliente: string, adquirente: string, file: File) {
    const endpoint = environment.APIEndpoint.valueOf();
    const formData: FormData = new FormData();
    if ( file != null ) {
      formData.append('file', file, file.name);
    }

    //return this._http.post(endpoint + 'conciliacao/import/' + cliente + "/" + periodo + "/" + periodoate, formData, {
   // console.log ( 'https://2jo98y6npa.execute-api.us-east-1.amazonaws.com/dev/omnipos-conciliacao-dev/' + cliente + '/' + adquirente + '/' + file.name  );
    return this._http.put(  this.URL_APIS3 + cliente + '/' + adquirente + '/' + file.name , formData, {
          headers: new HttpHeaders()
               .append('Content-Type', 'text/plain')
               .append('Accept', 'text/plain')
               .append('Access-Control-Allow-Headers', 'Content-Type')
               .append('Access-Control-Allow-Credentials', 'true')
               .append('Access-Control-Allow-Methods', '*')
               .append('Access-Control-Allow-Origin', '*')
               .append('Access-Control-Allow-Headers', '*')
          });

  }






  salvar(entidade: string, objeto: any) {

    return this._http.post(this.URL_API + entidade, JSON.stringify(objeto), {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    });

  }

  buscaConciliacao(periodo: string, periodoate: string, cliente: string, tipoDoc: string, adquirente: string): Observable<any> {
    if ( adquirente === "Todos") {
      adquirente = "";
    }


    const endpoint = this.URL_API + 'conciliacao/list?StatusConciliacao=PENDENTE&dataInicio=' + periodo + '&dataFim=' + periodoate + '&idCompany=' + cliente + '&tipoDocumento=' + tipoDoc + '&adquirente=' + adquirente ;

    return this._http.get(endpoint,  {
                                         headers: new HttpHeaders()
                                                 .append('Content-Type', 'application/json')
                                                 .append('Access-Control-Allow-Headers', 'Content-Type')
                                                 .append('Access-Control-Allow-Credentials', 'true')
                                                 .append('Access-Control-Allow-Methods', 'GET, PUT, POST, DELETE')
                                                 .append('Access-Control-Allow-Origin', '*')
    });

}

  getHistorico(origem: string, periodo: string, periodoate: string, cliente: string, adquirente: string, statusConciliacao: string): Observable<any> {
    if ( adquirente === "Todos") {
      adquirente = "";
    }

    const endpoint = this.URL_API + 'conciliacao/list?StatusConciliacao=' + statusConciliacao + '&dataInicio=' + periodo + '&dataFim=' + periodoate + '&idCompany=' + cliente  + '&adquirente=' + adquirente ;


    return this._http.get(endpoint,  {
                                         headers: new HttpHeaders()
                                                 .append('Content-Type', 'application/json')
                                                 .append('Access-Control-Allow-Headers', 'Content-Type')
                                                 .append('Access-Control-Allow-Credentials', 'true')
                                                 .append('Access-Control-Allow-Methods', 'GET, PUT, POST, DELETE')
                                                 .append('Access-Control-Allow-Origin', '*')
    });

  }

  processaConciliacoes(conciliacoes: any, periodo: string, cliente: string, usuario: string): Observable<any> {

    const endpoint = this.URL_API + 'enviar?idIntegration=CC004&model=ESB&idCompany=04403408000165&source=CONCIL&target=SAP';
    // console.log ( "*********** PAYLOAD CONCILIACAO ****************** ");
  //  console.log( "endpoint  "  );
   // console.log(  endpoint    );
    // console.log ( conciliacoes );

      return this._http.post(  endpoint , conciliacoes, {
              headers: new HttpHeaders()
                   .append('Content-Type', '*')
                   .append('Access-Control-Allow-Headers', 'Content-Type')
                   .append('Access-Control-Allow-Credentials', 'true')
                   .append('Access-Control-Allow-Methods', '*')
                   .append('Access-Control-Allow-Origin', '*')
                   .append('Access-Control-Allow-Headers', '*')
              });

  }

  processaLancamentos(conciliacoes: any, periodo: string, cliente: string, usuario: string): Observable<any> {

    const endpoint = this.URL_API + 'conciliacao/processaLancamento/' + periodo + '/' + cliente + '/' + usuario;


      return this._http.post(  endpoint , conciliacoes, {
              headers: new HttpHeaders()
                   .append('Content-Type', '*')
                   .append('Access-Control-Allow-Headers', 'Content-Type')
                   .append('Access-Control-Allow-Credentials', 'true')
                   .append('Access-Control-Allow-Methods', '*')
                   .append('Access-Control-Allow-Origin', '*')
                   .append('Access-Control-Allow-Headers', '*')
              });

  }


  buscaLancamentos(periodo: string, periodoate: string, cliente: string): Observable<any> {

    const endpoint = this.URL_API + 'conciliacao/lancamentosemreferencia/' + periodo + '/' + periodoate + '/' + cliente;

    return this._http.post(endpoint, null, {
       headers: new HttpHeaders()
                       .append('Content-Type', '*')
                       .append('Access-Control-Allow-Headers', 'Content-Type')
                       .append('Access-Control-Allow-Credentials', 'true')
                       .append('Access-Control-Allow-Methods', '*')
                       .append('Access-Control-Allow-Origin', '*')
                       .append('Access-Control-Allow-Headers', '*')
                  });

  }



  buscaStatusProcessamento ( ): Observable<any> {
    // const endpoint = this.URL_API + 'conciliacao/statusCarregarArq';
//console.log("############################ endpoint = " + endpoint);

    return null; //this._http.get(endpoint );

  }


}
