
export class PassosRoteamento {

	public id: string;
	public idEndpointDestino: string; 
	public idRoteamento: string;
	public identificacao: string;

    constructor(){
        
    }
}
