import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { TaxasAdministrativas } from '../../model/taxasadministrativas';
import { CadastrosService } from '../../services/cadastros.service';

@Component({
  selector: 'app-taxasadministrativas',
  templateUrl: './taxasadministrativas.component.html',
  styleUrls: ['./taxasadministrativas.component.scss']
})
export class TaxasAdministrativasComponent implements OnInit {

  movSub: Subscription;
  taxasadministrativas: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private modalService: ModalService,
      private cadastrosService: CadastrosService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){

  }

  ngOnInit() {
    this.gettaxasadministrativass();
  }

  async gettaxasadministrativass(){

    this.taxasadministrativas = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();

      this.movSub = this.cadastrosService.listar("taxasadministrativas/list?idCompany=PANASONIC").subscribe(
        (result: any) =>{
          console.log("BUSCA DAS TaxasAdministrativas");

          this.taxasadministrativas =  result;
          console.log(result);

          this.spinner.hide();

       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }


  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novotaxasadministrativas(){
    this.modalService.modalDetalhesTaxasAdministrativas.emit(null);
  }

  editar(taxasadministrativas: TaxasAdministrativas){
    this.modalService.modalDetalhesTaxasAdministrativas.emit(taxasadministrativas);
  }

  excluir(taxasadministrativas: TaxasAdministrativas){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{

        if(confirmed){

          this.cadastrosService.excluir("taxasadministrativas/delete", taxasadministrativas).subscribe(
            (result) =>{

              this.toastr.success('Taxas Administrativas excluída com sucesso!', 'Sucesso!');
              this.gettaxasadministrativass();
              console.log(result);

            },
            error=> {

              this.toastr.error(error.message, 'Erro!');
              console.log(error);

            }
          );
        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }


}
