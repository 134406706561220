import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EndpointComponent } from './endpoint/endpoint.component';
import { ClienteComponent } from './cliente/cliente.component';
import { AmbienteComponent } from './ambiente/ambiente.component';
import { ConfPainelComponent } from './confpainel/confpainel.component';
import { FormatoMensagemComponent } from './formatomensagem/formatomensagem.component';
import { TipoInterfaceComponent } from './tipointerface/tipointerface.component';
import { IntegracaoComponent } from './integracao/integracao.component';
import { ModeloMediadorComponent } from './modelomediador/modelomediador.component';
import { TipoComunicacaoComponent } from './tipocomunicacao/tipocomunicacao.component';
import { TipoProcessamentoComponent } from './tipoprocessamento/tipoprocessamento.component';
import { TipoMediadorComponent } from './tipomediador/tipomediador.component';
import { RoteamentoComponent } from './roteamento/roteamento.component';
import { LogComponent } from './log/log.component';
import { UsuariosMonitorComponent } from './usuariosmonitor/usuariosmonitor.component';
import { UsuariosApiComponent } from './usuariosapi/usuariosapi.component';
import { DeParaGenericComponent } from './deparageneric/deparageneric.component';
import { PassosRoteamentoComponent } from './passosroteamento/passosroteamento.component';
import { TaxasAdministrativasComponent } from './taxas-adminitrativas/taxasadministrativas.component';
import { ConciliacaoComponent } from './conciliacao/conciliacao.component';
import {AuthGuard} from "../auth/auth.guard";
import {HistoricoConciliacaoComponent} from "./historico-conciliacao/hist-conciliacao.component";
import {RelatorioTrayXSAPComponent} from "./relatorio-trayxsap/relatorio-trayxsap.component";
import { RelatorioPedidosNaoIntegradosComponent} from "./relatorio-pedidosnaointegrados/relatorio-pedidosnaointegrados.component";
import { RelatorioIntegracaoComponent } from "./relatorio-integracao/relatorio-integracao.component";
import { RelatorioPedidosStatusLogisticaComponent } from './relatorio-pedidostatuslogistica/relatorio-pedidostatuslogistica.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    data: {
      title: 'components'
    },
    children: [
      {
        path: 'usuariosmonitor',
        canActivate: [AuthGuard],
        component: UsuariosMonitorComponent,
        data: {
          title: 'Usuários Monitor'
        },
      },
      {
        path: 'usuariosapi',
        canActivate: [AuthGuard],
        component: UsuariosApiComponent,
        data: {
          title: 'Usuários Api'
        },
      },
      {
        path: 'logs',
        canActivate: [AuthGuard],
        component: LogComponent,
        data: {
          title: 'Logs'
        },
      },
      {
        path: 'endpoint',
        canActivate: [AuthGuard],
        component: EndpointComponent,
        data: {
          title: 'Endpoints'
        },
      },
      {
        path: 'cliente',
        canActivate: [AuthGuard],
        component: ClienteComponent,
        data: {
          title: 'Clientes'
        }
      },
             {
               path: 'ambiente',
               canActivate: [AuthGuard],
               component: AmbienteComponent,
               data: {
                 title: 'Ambientes'
               }
             },
             {
               path: 'confpainel',
               canActivate: [AuthGuard],
               component: ConfPainelComponent,
               data: {
                 title: 'Conf do Painel'
               }
             },
    {
        path: 'formatomensagem',
        canActivate: [AuthGuard],
        component: FormatoMensagemComponent,
        data: {
          title: 'Formato de Mensagem'
        }
      },
      {
        path: 'tipointerface',
        canActivate: [AuthGuard],
        component: TipoInterfaceComponent,
        data: {
          title: 'Tipo de Interface'
        }
      },
      {
        path: 'integracao',
        canActivate: [AuthGuard],
        component: IntegracaoComponent,
        data: {
          title: 'Integração'
        }
      },
      {
        path: 'modelomediador',
        canActivate: [AuthGuard],
        component: ModeloMediadorComponent,
        data: {
          title: 'Modelo Mediador'
        }
      },
      {
        path: 'tipocomunicacao',
        canActivate: [AuthGuard],
        component: TipoComunicacaoComponent,
        data: {
          title: 'Tipo Comunicação'
        }
      },
      {
        path: 'tipomediador',
        canActivate: [AuthGuard],
        component: TipoMediadorComponent,
        data: {
          title: 'Tipo Mediador'
        }
      },
      {
        path: 'tipoprocessamento',
        canActivate: [AuthGuard],
        component: TipoProcessamentoComponent,
        data: {
          title: 'Tipo Processamento'
        }
      },
      {
        path: 'roteamento',
        canActivate: [AuthGuard],
        component: RoteamentoComponent,
        data: {
          title: 'Roteamento'
        }
      },
      {
        path: 'passosroteamento',
        canActivate: [AuthGuard],
        component: PassosRoteamentoComponent,
        data: {
          title: 'PassosRoteamento'
        }
      },
      {
        path: 'deparageneric',
        canActivate: [AuthGuard],
        component: DeParaGenericComponent,
        data: {
          title: 'De/Para Generic'
        },
      },
      {
        path: 'taxasadministrativas',
        canActivate: [AuthGuard],
        component: TaxasAdministrativasComponent,
        data: {
          title: 'Taxas Administrativas'
        },
      },
      {
        path: 'conciliacao',
        canActivate: [AuthGuard],
        component: ConciliacaoComponent,
        data: {
          title: 'Conciliacão'
        },
      },
      {
        path: 'histconciliacao',
        canActivate: [AuthGuard],
        component: HistoricoConciliacaoComponent,
        data: {
          title: 'Histórico Conciliacão'
        },
      },
      {
        path: 'trayxsap',
        canActivate: [AuthGuard],
        component: RelatorioTrayXSAPComponent,
        data: {
          title: 'Tray X SAP'
        },
      },
      {
        path: 'pedidosnaointegrados',
        canActivate: [AuthGuard],
        component: RelatorioPedidosNaoIntegradosComponent,
        data: {
          title: 'Pedidos Não Integrados'
        },
      },
      {
        path: 'relatorio-integracao',
        canActivate: [AuthGuard],
        component: RelatorioIntegracaoComponent,
        data: {
          title: 'Alertas das Integrações'
        },
      },
      {
        path: 'pedidosstatuslogistica',
        canActivate: [AuthGuard],
        component: RelatorioPedidosStatusLogisticaComponent,
        data: {
          title: 'Pedidos Status Logística'
        },
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ComponentsRoutingModule {}
