import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfPainel } from '../../../model/confpainelmap';
import { CadastrosService } from '../../../services/cadastros.service';

@Component({
  selector: 'app-detalhes-confpainel',
  templateUrl: 'confpainel-detalhes.component.html'
})
export class ConfPainelDetalhesComponent implements OnInit{

  @ViewChild('modalconfpainel', {static: false}) public modalconfpainel: ModalDirective;

  sub: boolean = false;
  _confpainel: ConfPainel = new ConfPainel();
  @Output() loadconfpainel = new EventEmitter();
  tituloInc: string = "Nova Configuração do Painel";
  tituloAlt: string = "Editar Configuração do Painel";
  titulo: string = this.tituloInc;
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  movSub: Subscription;
  roteamentos: any[];

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesConfPainel.subscribe(
        (p: ConfPainel) => {

          if(p == null){
            this.titulo = this.tituloInc
            this._confpainel = new ConfPainel();
          }else{
            this.titulo = this.tituloAlt
            this._confpainel = p;
          }

          this.limpaModal();

          this.getRoteamentos();
          this.modalconfpainel.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }


  hideModal(){
    this.modalconfpainel.hide();
    this.loadconfpainel.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == this.tituloAlt){
      this.atualizar();
    }else{
      this.salvar();
    }

    form.reset();
  }

  salvar(){

    let objconfpainel = JSON.parse(JSON.stringify(this._confpainel));

    this.cadastrosService.salvar("confpainel/insert", objconfpainel).subscribe(
      (result) =>{

        this.toastr.success('Configuração do Painel cadastrado com sucesso!', 'Sucesso!');
      //  console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );



  }

  atualizar(){

    let objconfpainel = JSON.parse(JSON.stringify(this._confpainel));

    this.cadastrosService.atualizar("confpainel/update", objconfpainel, objconfpainel.id).subscribe(
      (result) =>{

        this.toastr.success('Configuração do Painel atualizado com sucesso!', 'Sucesso!');
        //console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );
  }


  async getRoteamentos(){

    this.roteamentos = [];

    if (this.movSub  && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.movSub = this.cadastrosService.listar("roteamento/list").subscribe(
        (result: any) =>{

          this.roteamentos =  result;
          console.log(result);

       },error=>{
            console.log(error);
       });

    }


  }



}
