import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CadastrosService } from '../../../services/cadastros.service';
import { UsuarioApi } from '../../../model/usuarioApi';

@Component({
  selector: 'app-detalhes-usuariosapi',
  templateUrl: 'usuariosapi-detalhes.component.html'
})
export class UsuariosApiDetalhesComponent implements OnInit{

  @ViewChild('modalusuarioapi', {static: false}) public modalusuariosapi: ModalDirective;

  sub: boolean = false;
  _usuarioapi: UsuarioApi = new UsuarioApi();
  @Output() loadusuariosapi = new EventEmitter();
  titulo: string = "Novo Usuario Api";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastroService: CadastrosService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesUsuariosApi.subscribe(
        (p: UsuarioApi) => {

          if(p == null){
            this.titulo = "Novo Usuario Api"
            this._usuarioapi = new UsuarioApi();
          }else{
            this.titulo = "Editar Usuario Api"
            this._usuarioapi = p;
          }

          this.limpaModal();

          this.modalusuariosapi.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  hideModal(){
    this.modalusuariosapi.hide();
    this.loadusuariosapi.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar Usuario Api"){
      this.atualizar();
    }else{
      this.salvar();
    }

    form.reset();
  }

  salvar(){

    let objusuariosapi = JSON.parse(JSON.stringify(this._usuarioapi));

    this.cadastroService.salvar("user/insert", objusuariosapi).subscribe(
      (result) =>{

        this.toastr.success('Usuario Api cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );



  }

  atualizar(){

    let objusuariosapi = JSON.parse(JSON.stringify(this._usuarioapi));

    this.cadastroService.atualizar("user/update", objusuariosapi, objusuariosapi.username).subscribe(
      (result) =>{

        this.toastr.success('Usuario Api atualizado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );
  }
}
