export const environment = {
  production: false,


   firebaseConfig : {
     apiKey: "AIzaSyCHYpq0SxIslUA6Q6lwWA2lX2-qyT8pXRo",
     authDomain: "monitoromnipos.firebaseapp.com",
     databaseURL: "https://monitoromnipos.firebaseio.com",
     projectId: "monitoromnipos",
     storageBucket: "monitoromnipos.appspot.com",
     messagingSenderId: "226554511940",
     appId: "1:226554511940:web:f2a0cee85f48c9297c7211",
     measurementId: "G-3N5XFMEZS0"
  },

  // DEV
  APIEndpoint: 'https://core.omnipos.dev.br/api/v1/',
  KEY_API: 'AIzaSyCHYpq0SxIslUA6Q6lwWA2lX2-qyT8pXRo' ,
  S3Endpoint: 'https://files.omnipos.dev.br/api/v1/omnipos-conciliacao-dev/'




  // APIEndpoint: 'https://omnipos.coachit.com.br:8047/api/v1/',  // PRD
  // KEY_API: 'AIzaSyBZKpiUUu0PxYxL3Nox4iXTOG7U9uveVrA',  // PRD
  // S3Endpoint: 'https://files.omnipos.com.br/api/v1/omnipos-conciliacao/'   // PRD

};

