
export class PedStatusLogistica {

  public Pedido: string;
  public Faturado: string;
  public Remessa: string;

  constructor(){

  }
}
