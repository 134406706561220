
export class Filtro {

  public id: string;
	public cliente: string;
	public idIntegration: string;
	public source: string;
	public target: string;
	public status: string;
	public statusErro: string;
	public dataBegin: string;
	public dataEnd: string;
	public idRef1: string;
	public idRef2: string;
	public idRef3: string;
  public adquirente: string;

    constructor(){
		this.statusErro = "Todos";
		this.idRef1 = "";
		this.idRef2 = "";
		this.idRef3 = "";
		this.adquirente = "";
    }
}
