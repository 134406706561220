
export class ConfPainelMap {

    public projeto: string;
    public statusProjeto: string;
    public confPainelList: ConfPainel[];


    constructor(){

    }
}


export class ConfPainel {

    public id: string;
    public id_roteamento: string;
    public code_retorno: string;
    public body_retorno: string;
    public projeto: string;
    public ativo: string;

    public dt_teste: string;
    public code_teste: string;
    public body_teste: string;
    public status: string;

    constructor(){

    }
}
