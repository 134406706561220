import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Ambiente } from '../../model/ambiente';
import { CadastrosService } from '../../services/cadastros.service';

@Component({
  selector: 'app-ambiente',
  templateUrl: './ambiente.component.html',
  styleUrls: ['./ambiente.component.scss']
})
export class AmbienteComponent implements OnInit {

  movSub: Subscription;
  ambiente: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private modalService: ModalService,
      private cadastrosService: CadastrosService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){

  }

  ngOnInit() {
    this.getAmbientes();
  }

  async getAmbientes(){

    this.ambiente = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();

      this.movSub = this.cadastrosService.listar("ambiente/list").subscribe(
        (result: any) =>{

          this.ambiente =  result;
          console.log(result);

          this.spinner.hide();

       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }


  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novoambiente(){
    this.modalService.modalDetalhesAmbiente.emit(null);
  }

  editar(ambiente: Ambiente){
    this.modalService.modalDetalhesAmbiente.emit(ambiente);
  }

  excluir(ambiente: Ambiente){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{

        if(confirmed){
          this.cadastrosService.excluir("ambiente/delete", ambiente).subscribe(
            (result) =>{

              this.toastr.success('Ambiente excluído com sucesso!', 'Sucesso!');
              this.getAmbientes();
              console.log(result);

            },
            error=> {

              this.toastr.error(error.message, 'Erro!');
              console.log(error);

            }
          );
        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }


}
