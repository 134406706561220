import {Component, OnDestroy, OnInit} from '@angular/core';
import { NgForm } from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import { AuthService } from './auth.service';
import { FirebaseServiceProvider } from '../providers/firebase-service';
import { ClientesService } from '../services/clientes.service';
import { Subscription } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-login-by-url',
  template: '<body></body>'
})
export class AuthByUrlComponent {

  isLoading = false;
  error: string = null;
  movSub: Subscription;
  email: string;
  senha: string;
  show: boolean = false;

  constructor(
      private authService: AuthService,
      private route: ActivatedRoute,
      private router: Router,
      private fbService: FirebaseServiceProvider
  ) {
    this.fbService.init('global').then(res => {
      this.autoLoginByURL();
    })
      .catch(err => {
        console.log(err);
      });
  }

  efetuaLogin(email, password, _data) {
    this.authService.login(email, password)
      .then(
        (result) => {

          this.isLoading = false;

          if (result.user.emailVerified || true) {
            // tslint:disable-next-line:max-line-length
            const sub = this.authService.handleAuthenticationSub(result.user.email, result.user.uid, result.user.refreshToken, 3600).subscribe(res => {
              if (res.length > 0) {

                localStorage.setItem('userMonitorOmniposPerfil', JSON.stringify(res[0]));

                if (this.movSub && this.movSub.closed === false) {
                  this.movSub.unsubscribe();
                }

                if (!this.movSub || this.movSub.closed) {

                  localStorage.setItem('dataConciliacao', _data);
                  this.router.navigate(['/dashboard']);

                }

              }
              sub.unsubscribe();
            });



          }

        },
        error => {
          console.log(error);
          this.error = this.authService.handleError(error.code);
          this.isLoading = false;
        }
      );
  }

  autoLoginByURL() {
    //console.log('autoLoginByURL');
    const _email = this.route.snapshot.queryParams.email;
    const _senha = this.route.snapshot.queryParams.senha;
    const _data = this.route.snapshot.queryParams.data;

    if (_email && _senha) {
      this.efetuaLogin(_email, _senha, _data);
    } else {
      this.show = true;
    }
  }


}
