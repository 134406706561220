import { Directive, Input , ElementRef, Renderer2, OnInit} from '@angular/core';

@Directive({
  selector: '[appIntegracao]'
})
export class IntegracaoDirective implements OnInit{
    
  @Input() id: string;
  @Input() integracao: any[];

  constructor(private elRef: ElementRef, private render: Renderer2){

  }

  ngOnInit(){

    if(this.integracao){
      for(var i = 0; i < this.integracao.length; i++){

        if(this.integracao[i].id == this.id){
  
          const text = this.render.createText(this.integracao[i].descricao);
    
          this.render.appendChild(this.elRef.nativeElement, text);
        }
      }
    }
   

  }
    

}

