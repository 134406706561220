import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap';
import {ModalService} from '../../../services/modal.service';
import * as $ from 'jquery';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-conciliacao-modal',
  templateUrl: 'conciliacao-modal.component.html'
})
export class ConciliacaoModalComponent implements OnInit {

  @ViewChild('modalconciliacao', {static: false}) public modalConciliacao: ModalDirective;

  sub: boolean = false;
  @Output() finalizaProcessamento = new EventEmitter();
  titulo: string = 'Processar conciliação';
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  justificativa: string = '';
  precisaJustificativa: boolean = false;
  registros: any[];
  qtdRegistros: number;

  constructor(
    private modalService: ModalService) {
  }

  ngOnInit() {

    if (this.sub === false) {

      this.sub = true;

      this.modalService.modalConciliacao.subscribe(
        (reg: any[]) => {

          this.justificativa = '';
          this.registros = reg;
          this.qtdRegistros = this.registros.length;
          this.limpaModal();
          this.precisaJustificativa = false;
          this.checkJutificativa();

          this.modalConciliacao.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  checkJutificativa() {
    const conciliacoesSelecionadas = [];
    for (const c of this.registros) {
      if (c.selecionado) {
      //  console.log(c);
        conciliacoesSelecionadas.push(c);

        if ((c.diferenca > 0 && c.diferenca > c.ajuste_permitido) || (c.diferenca < 0 && c.diferenca * -1 > c.ajuste_permitido)) {
          this.precisaJustificativa = true;
        }
      }
    }
  }

  hideModal() {
    this.modalConciliacao.hide();
  }

  confirmar() {

    for (const c of this.registros) {
      if (c.selecionado) {

        if ((c.diferenca > 0 && c.diferenca > c.ajuste_permitido) || (c.diferenca < 0 && c.diferenca * -1 > c.ajuste_permitido)) {
          c.justificativa = this.justificativa;
        }
      }
    }

    const obj = {
      'justificativa': this.justificativa,
      'precisaJustificativa': this.precisaJustificativa,
      'registros': this.registros
    };

    this.modalService.modalConciliacaoRetorno.emit(obj);
    this.modalConciliacao.hide();
  }

  limpaModal() {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

}
