import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CadastrosService } from '../../services/cadastros.service';
import { ModalService } from '../../services/modal.service';
import { Filtro } from '../../model/filtro';
import { LazyLoadEvent } from 'primeng/primeng';
import { CrudService } from '../../services/crudService';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {

  log: any[];
  logstatus: any[];
  tipointerface: any[];
  fileReader: FileReader;
  fileToRead: File;
  filtro: Filtro = new Filtro();
  movSub: Subscription;
  movSub1: Subscription;
  movSub2: Subscription;
  movSub3: Subscription;
  movSub4: Subscription;
  movSub5: Subscription;
  movSub6: Subscription;
  movSub7: Subscription;
  movSub8: Subscription;
  movSub9: Subscription;
  integracao: any[];
  clientes: any[];
  ambientes: any[];
  periodoDe = new Date(new Date().getFullYear(),  new Date().getMonth(), 1, 0, 0, 0, 0);
  periodoAte = new Date(new Date().getFullYear(),  new Date().getMonth(), 1, 0, 0, 0, 0);
  erros: any[];
  usuariosmonitor: any;
  movSubUsuario: Subscription;
  usuarioLogado: any;
  totalDataLength: number;
  page: any = 1;
  size: any = 10;
  carregando: boolean = false;
  totalElements:any = 0;

  constructor(
      private spinner: NgxSpinnerService,
      private cadastrosService: CadastrosService,
      private crudService: CrudService,
      private modalService: ModalService
  ) {
  }

  filtrar() {
//    console.log(this.filtro);
    this.carregando = true;
    this.totalElements = 0;
    this.getlogs();
  }

  ngOnInit() {

    // this.spinner.show();
    this.filtro.dataBegin = this.periodoDe.toISOString().split('T')[0];
    this.filtro.dataEnd = this.periodoAte.toISOString().split('T')[0];
    // this.getlogs();
    this.getusuariosmonitors();
    this.getintegracaos();
    this.getClientes();
    this.getAmbientes();
    this.getlogsstatus();

  }

  setErros() {
    this.erros = [];
    this.erros.push({ 'id' : 'Sucesso'});
    this.erros.push({ 'id' : 'Erro'});
    this.erros.push({ 'id' : 'Todos'});
    this.getintegracaos();
  }

  async getusuariosmonitors() {

    this.usuariosmonitor = [];

    if (this.movSubUsuario && this.movSubUsuario.closed === false) {
      this.movSubUsuario.unsubscribe();
    }

    if (!this.movSubUsuario || this.movSubUsuario.closed) {

      this.movSubUsuario = this.crudService.listarByFiltro('usuarios', null).subscribe(
        (result: any) => {

          this.usuariosmonitor =  result;

          const user = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
          for (const u of this.usuariosmonitor) {
            if (u.id === user.id) {
                this.usuarioLogado = u;
            }
          }

          if (this.usuarioLogado) {

            this.setErros();
          }

       }, error => {
          this.spinner.hide();
            console.log(error);
       });

    }


  }

  async getlogs() {

    if (this.carregando) {
      this.spinner.show();

      this.log = [];

      if (this.movSub && this.movSub.closed === false) {
        this.movSub.unsubscribe();
      }

      if (!this.movSub || this.movSub.closed) {

         if ( this.totalElements == 0 ) {
                   console.log('busca total elements');

             this.movSub = this.cadastrosService.listarByPageFilter('log/qtdeRegistro', null, null, this.filtro).subscribe(
                (result:any) =>{
                      console.log('entrou result');
                      console.log(' result ' + result);
                      console.log('  result.qtde ' +  result.qtde);

                    this.totalElements = result.qtde;
                    console.log('this.totalElements =' + this.totalElements );
            }
          )
        }

        this.movSub = this.cadastrosService.listarByPageFilter('log/list', this.page, this.size, this.filtro).subscribe(
          (result: any) => {

            this.log =  result;
            console.log ( this.log );
            this.totalDataLength = this.totalElements;

            let idlogcore = 0;
            let cor = '#ffffbc';

            //this.log.sort((a, b) => (a.idlogcore > b.idlogcore) ? -1 : ((b.idlogcore > a.idlogcore) ? 1 : 0));


             for (const item of this.log) {
              // tslint:disable-next-line:triple-equals
              if (item.idlogcore != idlogcore) {

                if (cor === '#cbffff') {
                  item.background = '#ffffbc';
                  cor = '#ffffbc';
                } else {
                  item.background = '#cbffff';
                  cor = '#cbffff';
                }


              } else {
                item.background = cor;
              }

              idlogcore = item.idlogcore;

            }

            this.spinner.hide();

        }, error => {
              console.log(error);
              this.spinner.hide();
        });

      }

    }

  }

  async getlogsstatus() {

    this.logstatus = [];

    if (this.movSub9 && this.movSub9.closed === false) {
      this.movSub9.unsubscribe();
    }

    if (!this.movSub9 || this.movSub9.closed) {

      this.movSub9 = this.cadastrosService.listar('statuslog/list').subscribe(
        (result: any) => {

          this.logstatus =  result;
          this.logstatus.push({ 'id' : 'Todos', 'descricao': 'Todos'});

         // this.getlogs();
       }, error => {
            console.log(error);
            this.spinner.hide();
       });

    }


  }

  async getClientes() {

    this.clientes = [];

    if (this.movSub1 && this.movSub1.closed === false) {
      this.movSub1.unsubscribe();
    }

    if (!this.movSub1 || this.movSub1.closed) {

      this.movSub1 = this.cadastrosService.listar('cliente/list').subscribe(
        (result: any) => {

          if (this.usuarioLogado.perfil === 'ADMINISTRADOR') {

            for (const cli of result) {
              if (cli.tipo === 'CLIENTE') {
                this.clientes.push(cli);
              }
            }
            this.clientes.push({ 'id' : 'Todos'});
          } else {
            this.clientes.push({ 'id' : this.usuarioLogado.cliente});
            this.filtro.cliente = this.usuarioLogado.cliente;
            this.getintegracaos();
          }


       }, error => {
            console.log(error);
            this.spinner.hide();
       });

    }

  }

  changeClientes() {
    this.getAmbientes();
    this.getintegracaos();
  }


  async getAmbientes() {

    this.ambientes = [];

    if (this.movSub4 && this.movSub4.closed === false) {
      this.movSub4.unsubscribe();
    }

    if (!this.movSub4 || this.movSub4.closed) {

      this.movSub4 = this.cadastrosService.listar('ambiente/list').subscribe(
        (result: any) => {

          if (this.usuarioLogado.perfil === 'ADMINISTRADOR') {

            for (const a of result) {
              if (this.filtro.cliente === a.id_cliente || this.filtro.cliente === 'Todos') {
                this.ambientes.push(a);
              }
            }

           } else {
             for (const a of result) {
               if (a.id_cliente === this.usuarioLogado.cliente) {
                 this.ambientes.push(a);
               }
            }
          }

          this.ambientes.push({ 'id' : 'Todos'});


       }, error => {
            console.log(error);
            this.spinner.hide();


       });

    }

  }

  async getintegracaos() {

    this.integracao = [];

    if (this.movSub2 && this.movSub2.closed === false) {
      this.movSub2.unsubscribe();
    }

    if ((!this.movSub2 || this.movSub2.closed)  ) {

      if (  this.filtro.cliente  != undefined ) {
      this.movSub2 = this.cadastrosService.listar('integracao/list?idCompany=' + this.filtro.cliente ).subscribe(
        (result: any) => {

         this.integracao =  result;
 this.integracao.push({ 'id' : 'Todos', 'descricao': 'Todos'});


       }, error => {
            console.log(error);
            this.spinner.hide();
       });
       }
     this.integracao.push({ 'id' : 'Todos', 'descricao': 'Todos'});

    }


  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }
  }

  detalheModal(log: any) {
    this.modalService.modalDetalhesLog.emit(log);
  }

  onChangeDateDe(e) {
    this.filtro.dataBegin = e;
  }

  onChangeDateAte(e) {
    this.filtro.dataEnd = e;
  }

  loadDataLazy(event: LazyLoadEvent): void {

    const first = event.first;
    const rows = event.rows;

    this.page = (first / rows)+1;
    this.size = 10;
    this.getlogs();
  }


}
