
export class UsuarioApi {

    public id: string;
    public username: string;
    public senha: string;

    constructor(){
        
    }
}
