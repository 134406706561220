import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CadastrosService } from '../../../services/cadastros.service';
import { PassosRoteamento } from '../../../model/passsosroteamento';

@Component({
  selector: 'app-detalhes-passosroteamento',
  templateUrl: 'passosroteamento-detalhes.component.html'
})

export class PassosRoteamentoDetalhesComponent implements OnInit{

  @ViewChild('modalpassosroteamento', {static: false}) public modalpassosroteamento: ModalDirective;

  sub: boolean = false;
  _passosroteamento: PassosRoteamento = new PassosRoteamento();
  @Output() loadpassosroteamento = new EventEmitter();
  titulo: string = "Novo Passo Roteamento";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  clientes: any[];
  integracao: any[];
  tipoprocessamento: any[];
  ambientes: any[];
  tipocomunicacao: any[];
  formatomensagem: any[];
  tipomediador: any[];
  modelos: any[];

  movSub: Subscription;
  movSub2: Subscription;
  movSub3: Subscription;
  movSub4: Subscription;
  movSub5: Subscription;
  movSub6: Subscription;
  movSub7: Subscription;
  movSub8: Subscription;
  endpoints: any[];
  movSub9: Subscription;
  roteamentos: any[];
  movSub10: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesPassosRoteamento.subscribe(
        (p: PassosRoteamento) => {

          if(p == null){
            this.titulo = "Novo Passo Roteamento"
            this._passosroteamento = new PassosRoteamento();
          }else{
            this.titulo = "Editar Passo Roteamento"
            this._passosroteamento = p;
          }

          this.limpaModal();
          this.getendpointss();
          this.getroteamentos();
          this.modalpassosroteamento.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }


  async getendpointss(){

    this.endpoints = [];

    if (this.movSub9 && this.movSub9.closed == false){
      this.movSub9.unsubscribe();
    }

    if (!this.movSub9 || this.movSub9.closed){

      this.movSub9 = this.cadastrosService.listar("endpoint/list").subscribe(
        (result: any) =>{

          this.endpoints =  result;
          console.log(result);

       },error=>{
            console.log(error);
       });

    }


  }


  async getroteamentos(){

    this.roteamentos = [];

    if (this.movSub10 && this.movSub10.closed == false){
      this.movSub10.unsubscribe();
    }

    if (!this.movSub10 || this.movSub10.closed){

      this.movSub10 = this.cadastrosService.listar("roteamento/list").subscribe(
        (result: any) =>{

          this.roteamentos =  result;
          console.log(result);

       },error=>{
            console.log(error);
       });

    }


  }

  hideModal(){
    this.modalpassosroteamento.hide();
    this.loadpassosroteamento.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar Passo Roteamento"){
      this.atualizar();
    }else{
      this.salvar();
    }

    form.reset();
  }

  salvar(){

    let objpassosroteamento = JSON.parse(JSON.stringify(this._passosroteamento));

    this.cadastrosService.salvar("passosroteamento/insert", objpassosroteamento).subscribe(
      (result) =>{

        this.toastr.success('Passo Roteamento cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );



  }

  atualizar(){

    let objpassosroteamento = JSON.parse(JSON.stringify(this._passosroteamento));

    this.cadastrosService.atualizar("passosroteamento/update", objpassosroteamento, objpassosroteamento.id).subscribe(
      (result) =>{

        this.toastr.success('Passo Roteamento atualizado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );
  }
}
