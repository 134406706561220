import { NgModule } from '@angular/core';
import { SimNaoDirective } from './sim-nao.directive';
import { FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import { StatusDirective } from './status.directive';
import { VerticalDirective } from './vertical.directive';
import { ErroDirective } from './erro.directive';
import { IntegracaoDirective } from './integracao.directive';

@NgModule({
    declarations: [
        SimNaoDirective,
        FileSelectDirective,
        FileDropDirective,
        StatusDirective,
        VerticalDirective,
        ErroDirective,
        IntegracaoDirective
    ],

    exports: [
        SimNaoDirective,
        FileSelectDirective,
        FileDropDirective,
        StatusDirective,
        VerticalDirective,
        ErroDirective,
        IntegracaoDirective
    ]
})
export default class DirectivesModule{}
