import { Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { CadastrosService } from '../../../services/cadastros.service';
import { DetalheNaoIntegrados } from '../../../model/DetalheNaoIntegrados';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-detalhes-relatorio-integracao',
  templateUrl: 'relatorio-integracao-detalhes.component.html'
})

export class RelatorioIntegracaoDetalhesComponent implements OnInit{

  @ViewChild('modalrelatoriointegracao', {static: false}) public modalrelatoriointegracao: ModalDirective;
  @Output() loadrelatoriointegracao = new EventEmitter();

  sub: boolean = false;
  detalheNaoIntegrados: DetalheNaoIntegrados;
  movSub9: any;

  constructor(
    private modalService: ModalService,
    private spinner: NgxSpinnerService,
    private cadastrosService: CadastrosService) {
  }

  ngOnInit() {
    console.log ( "chamou ngOnInit Detalhe Relatorio Integração " );

    if(this.sub == false){

      this.sub = true;
 console.log ( " this.modalService.modalDetalhesRelatorioIntegracao =  " +  this.modalService.modalDetalhesRelatorioIntegracao );

      this.modalService.modalDetalhesRelatorioIntegracao.subscribe(
        (p: DetalheNaoIntegrados) => {

            this.detalheNaoIntegrados = p;
            console.log ( "this.detalheNaoIntegrados  =  " + p.endpoint  );

            this.limpaModal();

            this.modalrelatoriointegracao.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }


  hideModal(){
    this.modalrelatoriointegracao.hide();
    this.loadrelatoriointegracao.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

}
