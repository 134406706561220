import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, PLATFORM_ID, NgZone, Inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../environments/environment';
import { AngularFirestore } from '@angular/fire/firestore';
import * as CryptoJS from 'crypto-js';
/**
 * Provider que implementa a inicialização dinâmica do Firebase no projeto
 */
@Injectable()
export class FirebaseServiceProvider {

  /** Conexão ao Authentication*/
  private afAuth: AngularFireAuth;

  /** Conexão ao Firestore Database*/
  private afFs: AngularFirestore;

  // Parametros de configuração: Deve ser alterado para o build dev/prd
  private firebaseConfig: any = environment.firebaseConfig;

  constructor(
    private http: HttpClient,
    private zone: NgZone ) { }

  get dbCliente() {
    this.init('cliente');
    return this.afFs;
  }

  get dbGlobal() {
    this.init('global');
    return this.afFs;
  }

  get auth() { return this.afAuth }

  public async init(db: string): Promise<void> {

   // console.log("firebase init");

    let config: any;
    let appName: string;

    config = environment.firebaseConfig;
    appName = 'global';

    this.initializeFirebaseApp(config, appName);
  }

  /**
   * Inicializa o AngularFire [Database, Storage, Auth]
   *
   * @param config Configuração do Firebase
   * @param appName nome do application
   */
  private initializeFirebaseApp(config: any, appName: string): void {

    this.afFs = new AngularFirestore(config, appName, true, null, PLATFORM_ID, this.zone, null);
    this.afAuth = new AngularFireAuth(this.firebaseConfig, appName, PLATFORM_ID, this.zone);

  }

}

/**
 * Inicializa a classe do Firebase
 * que fará a conexão dinâmica com o banco de dados do cliente
 */
export function firebaseInit(fbService: FirebaseServiceProvider) {
  return (): Promise<any> => {
    return fbService.init('global');
  }
}
