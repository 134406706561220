
export class Cliente {

    public id: string;
    public nome: string;
    public cnpj: string;
    public tipo: string;

    constructor(){

    }
}
