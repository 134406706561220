import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  UrlTree
} from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

import { AuthService } from './auth.service';
import {CrudService} from '../services/crudService';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private crudService: CrudService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    router: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Promise<boolean | UrlTree>
    | Observable<boolean | UrlTree> {
    // @ts-ignore
    return this.authService.user.pipe(
      take(1),
      map( user => {
        const isAuth = !!user;

        console.log(user);

        if (isAuth) {
          return this.getPermissoes(router.url);
        } else {
          return this.router.createUrlTree(['/login']);
        }

      })

    );
  }

  getPermissoes(rota: string) {
    const user = JSON.parse(localStorage.getItem('userMonitorOmniposPerfil'));

    console.log(user);
    user.perfil =   user.perfil.trim();
    if (user && user.perfil) {
      switch (user.perfil) {
        case 'ADMINISTRADOR': {
          return true;
        }
        case 'ADMNISTRADOR': {
          return true;
        }
        case 'MONITOR': {
          return rota === '/dashboard' || rota === '/components/logs' || rota === '/components/trayxsap' || rota === '/components/pedidosnaointegrados'
            || rota === '/components/pedidosstatuslogistica';
        }
        case 'ADMNISTRADOR CONCILIAÇÃO': {
          return rota === '/dashboard' || rota === '/components/logs' || rota === '/components/trayxsap'
            || rota === '/components/histconciliacao' || rota === '/components/conciliacao' || rota === '/components/taxasadministrativas'
            || rota === '/components/pedidosnaointegrados' || rota === '/components/pedidosstatuslogistica';
        }
        case 'ADMINISTRADOR CONCILIAÇÃO': {
          return rota === '/dashboard' || rota === '/components/logs' || rota === '/components/trayxsap'
            || rota === '/components/histconciliacao' || rota === '/components/conciliacao' || rota === '/components/taxasadministrativas'
            || rota === '/components/pedidosnaointegrados' || rota === '/components/pedidosstatuslogistica';
        }
        case 'OPERADOR CONCILIAÇÃO': {
          return rota === '/dashboard' || rota === '/components/logs' || rota === '/components/trayxsap'
            || rota === '/components/histconciliacao' || rota === '/components/conciliacao' || rota === '/components/pedidosnaointegrados'
            || rota === '/components/pedidosstatuslogistica';
        }
        default: {
          return this.router.createUrlTree(['/login']);
        }
      }
    } else {
      return this.router.createUrlTree(['/login']);
    }

  }


}
