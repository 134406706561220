import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {ModalService} from '../../services/modal.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {CadastrosService} from '../../services/cadastros.service';
import {OrigemLancamentosEnum} from '../../model/enumeration/origemLancamentosEnum';
import {RelatorioService} from '../../services/relatorio.service';
import {CrudService} from '../../services/crudService';
import {User} from '../../auth/user.model';
import {UsuarioMonitor} from '../../model/usuarioMonitor';
import * as FileSaver from 'file-saver';
import {BaseModal, BaseModalConfig, BasicModalService} from 'angular-basic-modal/index';

@Component({
  selector: 'app-trayxsap',
  templateUrl: './relatorio-trayxsap.component.html',
  styleUrls: ['./relatorio-traysap.component.scss']
})
export class RelatorioTrayXSAPComponent implements OnInit {

  movSub: Subscription;
  trayxsap: any[];
  origemLancamento: any = 'API';
  origemLancamentos = Object.keys(OrigemLancamentosEnum).map(key => OrigemLancamentosEnum[key]);
  fileToUpload: File = null;
  usuariosmonitor: UsuarioMonitor[];
  periodo: string;
  periodoate: string;
  loadingERP: boolean = true;
  perfil: string = '';
  todosRelatorio: any[];
  qtdRelatorioTray = 0;
  qtdRelatorioIntegrados = 0;
  qtdRelatorioNIntegrados = 0;
  qtdRelatorioDifMenor = 0;
  qtdRelatorioSemLancamento= 0;
  qtdRelatorioPagos = 0;
  qtdRelatorioAguardandoPag = 0;
  qtdRelatorioSeparados = 0;
  qtdRelatorioConferidos = 0;
  qtdRelatorioEmPreparacao = 0;
  qtdRelatorioEnviados = 0;
  qtdRelatorioEntregues = 0;
  qtdRelatorioOutros = 0;
  qtdRelatorioCanceladosInt = 0;
  qtdRelatorioCanceladosNInt = 0;
  qtdRelatorioAguardando = 0;
  pesquisa = '';
  cliente: string;
  movSub9: any;
  idref1: string;

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private cadastrosService: CadastrosService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private relatorioService: RelatorioService,
    private crudService: CrudService,
    private modal: BasicModalService
  ) {

  }

  exportExcel() {

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.trayxsap);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'historico_conciliação');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getPerfil() {
    const user = JSON.parse(localStorage.getItem('userMonitorOmnipos'));

    const sub = this.crudService.findByGeneric('usuarios', 'id', user.id ).subscribe(res => {
      if (res.length > 0) {
        this.perfil = res[0].perfil;
      }
      sub.unsubscribe();
    });
  }

  ngOnInit() {
    this.getusuariosmonitors();
    this.getPerfil();
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  buscar() {
    if (!this.periodo) {
      this.toastr.warning('Período de não informado', 'ATENÇÃO');
    } else  if (!this.periodoate) {
      this.toastr.warning('Período até não informado', 'ATENÇÃO');
    } else if (!this.origemLancamento) {
      this.toastr.warning('Origem do lançamento não informado', 'ATENÇÃO');
    } else {

      const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
      for (const u of this.usuariosmonitor) {
        if (u.email === user.email) {
          this.cliente = u.cliente;
        }
      }

      this.loadingERP = true;

      this.trayxsap = [];
      this.todosRelatorio = [];

        this.spinner.show();
      // tslint:disable-next-line:max-line-length
        const sub = this.relatorioService.getTrayXSap(this.periodo, this.periodoate, this.cliente).subscribe(data => {

          this.extracted(data);

          sub.unsubscribe();
        }, error => {
          this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
          console.log(error);
          this.spinner.hide();
        });

      // }
    }

  }

  calculaResumo() {


    this.qtdRelatorioTray = 0;
    this.qtdRelatorioIntegrados = 0;
    this.qtdRelatorioNIntegrados = 0;
    this.qtdRelatorioPagos = 0;
    this.qtdRelatorioAguardandoPag = 0;
    this.qtdRelatorioSeparados = 0;
    this.qtdRelatorioConferidos = 0;
    this.qtdRelatorioEmPreparacao = 0;
    this.qtdRelatorioEnviados = 0;
    this.qtdRelatorioEntregues = 0;
    this.qtdRelatorioCanceladosInt = 0;
    this.qtdRelatorioCanceladosNInt = 0;
    this.qtdRelatorioOutros = 0;
    this.qtdRelatorioAguardando = 0;

    for (const c of this.trayxsap) {

      if (c) {

      this.qtdRelatorioTray += 1;

        if (c.pedidoSAP !== '') {
          this.qtdRelatorioIntegrados += 1;
          c.sap = 'SAP';
        } else {
          if (c.statusSAP === 'AGUARDANDO INTEGRAÇÃO') {
            this.qtdRelatorioAguardando += 1;
          } else if (c.statusSAP === 'CANCELADO') {
            c.statusSAP = 'CANCELADO';
          } else {
            c.statusSAP = 'NÃO INTEGRADO';
            this.qtdRelatorioNIntegrados += 1;
          }
        }

        if (c.codStatusTray === 1) {

          this.qtdRelatorioPagos  += 1;

        } else if (c.codStatusTray === 2) {

          this.qtdRelatorioAguardandoPag  += 1;

        } else if (c.codStatusTray === 16) {

          this.qtdRelatorioSeparados  += 1;

        } else if (c.codStatusTray === 20) {

          this.qtdRelatorioConferidos  += 1;

        } else if (c.codStatusTray === 11) {

          this.qtdRelatorioEmPreparacao  += 1;

        } else if (c.codStatusTray === 9) {

          this.qtdRelatorioEnviados  += 1;

        } else if (c.codStatusTray === 18) {

          this.qtdRelatorioEntregues  += 1;
        } else if (c.codStatusTray >= 3 && c.codStatusTray <= 8) {

          if (c.pedidoSAP !== '') {
            this.qtdRelatorioCanceladosInt  += 1;
          } else{
            this.qtdRelatorioCanceladosNInt  += 1;
          }

        } else {
          this.qtdRelatorioOutros  += 1;
        }
      }

    }

  }

  pesquisar(tableMov, texto) {

    tableMov.filterGlobal(texto, 'contains');

  }
  diferencaZero() {
    this.trayxsap = [];
    for (const c of this.todosRelatorio) {
      if (c.diferenca === 0) {
        this.trayxsap.push(c);
      }
    }
  }

  diferencaMenorPermitido() {
    this.trayxsap = [];
    for (const c of this.todosRelatorio) {
      if (c.diferenca > 0 && c.diferenca <= c.ajuste_permitido) {
        this.trayxsap.push(c);
      }
    }
  }

  diferencaMaiorPermitido() {
    this.trayxsap = [];
    for (const c of this.todosRelatorio) {
      if (c.diferenca > c.ajuste_permitido) {
        this.trayxsap.push(c);
      }
    }
  }

  todos() {
    this.trayxsap =  this.todosRelatorio;
  }

  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }


  detalhe(p: any) {

    if (p.detalhe && p.detalhe.length > 0) {
      p.detalhe = [];
    } else {
      this.getDetalhe(p);
    }

  }

  detalhePayload(p: any) {

    if (p.payload) {
      alert(p.payload);
    } else {
      alert('Nenhum Payload localizado.');
    }


  }

  detalheTransformacao(p: any) {

    if (p.transformacao) {
      alert(p.transformacao);
    } else {
      alert('Nenhuma Transformação localizado.');
    }


  }

  detalheMensagem(p: any) {

    if (p.mensagem) {
      alert(p.mensagem);
    } else {
      alert('Nenhuma Retorno Mensagem localizado.');
    }


  }

  async getDetalhe(p: any) {

    this.spinner.show();

    if (this.movSub9 && this.movSub9.closed === false) {
      this.movSub9.unsubscribe();
    }

    if (!this.movSub9 || this.movSub9.closed) {

      this.movSub9 = this.cadastrosService.listar('log/detalheVenda?cliente=' + this.cliente + '&idref1=' + p.pedidoTray).subscribe(
        (result: any) => {

          if (result.length > 0) {
            p.detalhe = result;
          } else {
            this.toastr.warning('Não foi localizado nenhum detalhe para esse pedido!', 'ATENÇÃO');
          }

          this.spinner.hide();

        }, error => {
          this.spinner.hide();
          console.log(error);
        });

    }


  }

  async extracted(data) {

    if (data && data.length > 0) {

      this.trayxsap = data;
      this.todosRelatorio = data;
      this.calculaResumo();
      await this.spinner.hide();
      this.loadingERP = false;

      for (const c of this.trayxsap) {
        c.selecionado = false;
      }

    } else {
      this.toastr.warning('Não foi localizado nenhum histórico para esse período!', 'ATENÇÃO');
      this.trayxsap = [];
      this.todosRelatorio = [];
      await this.spinner.hide();
      this.loadingERP = false;
    }

  }

  async getusuariosmonitors() {

    this.usuariosmonitor = [];

    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed) {

      this.spinner.show();

      this.movSub = this.crudService.listarByFiltro('usuarios', null).subscribe(
        (result: any) => {

          this.usuariosmonitor = result;
          console.log(result);

          this.spinner.hide();

        }, error => {
          console.log(error);
          this.spinner.hide();
        });

    }


  }

  selecionaRelatorio(item: any) {
    item.selecionado = !item.selecionado;
  }

  verMsg(msg, status) {

    if (status === 'PROCESSADO') {
      this.toastr.info(msg, 'Mensagem');
    } else if (status === 'ERRO') {
      this.toastr.error(msg, 'Mensagem');
    }


  }

}


