import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { Filtro } from '../model/filtro';

@Injectable({
  providedIn: 'root'
})
export class CadastrosService {

  private URL_API = environment.APIEndpoint.valueOf();  //Local


  constructor(
    private _http: HttpClient
  ) {

  }

  listar(entidade: string): Observable<any> {

     return this._http.get(this.URL_API+entidade, {
      headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Access-Control-Allow-Headers', 'Content-Type')
            .append('Access-Control-Allow-Credentials', 'true')
            .append('Access-Control-Allow-Methods', '*')
            .append('Access-Control-Allow-Origin', '*')
            .append('Access-Control-Allow-Headers',     '*')
            .append('Vary', 'Origin')
    });

  }






  listarByFilter(entidade: string, filtro: Filtro): Observable<any> {

    return this._http.get(this.URL_API+entidade,  {
      headers: new HttpHeaders()
               .append('Content-Type', 'application/json')
               .append('Access-Control-Allow-Headers', 'Content-Type')
               .append('Access-Control-Allow-Credentials', 'true')
               .append('Access-Control-Allow-Methods', 'OPTIONS, HEAD, GET, PUT, POST, DELETE')
               .append('Access-Control-Allow-Origin', '*')
               .append('Access-Control-Allow-Headers',     '*')
               .append('Vary', 'Origin')
       });

     }

  listarByPageFilter(entidade: string, page: any, size: any, filtro: Filtro): Observable<any> {

  let url = '';

  url = this.URL_API+entidade+'?';
  if ( page != null ) url = url + 'pagina='+page+'&qtd='+size;

  url = url + '&idCompany=' +  ( filtro.cliente  != undefined ? filtro.cliente : '') ;
  url = url + '&idIntegration=' +  ( filtro.idIntegration  != undefined ? filtro.idIntegration : '');
  url = url + '&source=' + ( filtro.source  != undefined ? filtro.source : '');
  url = url + '&target=' + ( filtro.target  != undefined ? filtro.target : '');
  url = url + '&status=' + ( filtro.status  != undefined ? filtro.status : '');
  url = url + '&idref1=' + ( filtro.idRef1  != undefined ?  filtro.idRef1 : '');
  url = url + '&idref2=' + ( filtro.idRef2  != undefined ?  filtro.idRef2 : '');
  url = url + '&idref3=' + ( filtro.idRef3  != undefined ?  filtro.idRef3 : '');
  url = url + '&id=' + ( filtro.id  != undefined ?  filtro.id : '');
  url = url + '&dateBegin=' + ( filtro.dataBegin  != undefined ? filtro.dataBegin: '');
  url = url + '&dateEnd=' + ( filtro.dataEnd  != undefined ?  filtro.dataEnd : '');
  url = url + '&erro=' + ( filtro.statusErro  != undefined ?  filtro.statusErro :'');


    return this._http.get( url,  {
        headers: new HttpHeaders()
                 .append('Content-Type', 'application/json')
                 .append('Access-Control-Allow-Headers', 'Content-Type')
                 .append('Access-Control-Allow-Credentials', 'true')
                 .append('Access-Control-Allow-Methods', 'OPTIONS, HEAD, GET, PUT, POST, DELETE')
                 .append('Access-Control-Allow-Origin', '*')
                 .append('Access-Control-Allow-Headers',     '*')
                 .append('Vary', 'Origin')
         });

       }

  salvar(entidade: string, objeto: any){

    return this._http.post(this.URL_API+entidade, JSON.stringify(objeto), {
          headers: new HttpHeaders()
               .append('Content-Type', 'application/json')
               .append('Access-Control-Allow-Headers', 'Content-Type')
               .append('Access-Control-Allow-Credentials', 'true')
               .append('Access-Control-Allow-Methods', '*')
               .append('Access-Control-Allow-Origin', '*')
               .append('Access-Control-Allow-Headers', '*')
               .append('Vary', 'Origin')
       })

  }

  atualizar(entidade: string, objeto: any, id: any){

       return this._http.post(this.URL_API+entidade, JSON.stringify(objeto), {
         headers: new HttpHeaders()
                  .append('Content-Type', 'application/json')
                  .append('Access-Control-Allow-Headers', 'Content-Type')
                  .append('Access-Control-Allow-Credentials', 'true')
                  .append('Access-Control-Allow-Methods', 'OPTIONS, HEAD, GET, PUT, POST, DELETE')
                  .append('Access-Control-Allow-Origin', '*')
                  .append('Access-Control-Allow-Headers',     '*')
                  .append('Vary', 'Origin')
         })

  }







  excluir(entidade: string, objeto: any  ){
     return this._http.post(this.URL_API+entidade, JSON.stringify(objeto), {
          headers: new HttpHeaders()
               .append('Content-Type', 'application/json')
               .append('Access-Control-Allow-Headers', 'Content-Type')
               .append('Access-Control-Allow-Credentials', 'true')
               .append('Access-Control-Allow-Methods', '*')
               .append('Access-Control-Allow-Origin', '*')
               .append('Access-Control-Allow-Headers', '*')
               .append('Vary', 'Origin')
       })

  }











}
