import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {ModalService} from '../../services/modal.service';
import {ConfirmationDialogService} from '../confirmation-dialog/confirmation-dialog.service';
import {CadastrosService} from '../../services/cadastros.service';
import {OrigemLancamentosEnum} from '../../model/enumeration/origemLancamentosEnum';
import {StatusConciliacaoEnum} from '../../model/enumeration/statusConciliacaoEnum';
import {ConciliacaoService} from '../../services/conciliacao.service';
import {CrudService} from '../../services/crudService';
import {User} from '../../auth/user.model';
import {UsuarioMonitor} from '../../model/usuarioMonitor';
import * as FileSaver from "file-saver";

@Component({
  selector: 'app-histconciliacao',
  templateUrl: './hist-conciliacao.component.html',
  styleUrls: ['./hist-conciliacao.component.scss']
})
export class HistoricoConciliacaoComponent implements OnInit {

  movSub: Subscription;
  movSub4: Subscription;
  histconciiacao: any[];
  origemLancamento: any = 'API';
  origemLancamentos = Object.keys(OrigemLancamentosEnum).map(key => OrigemLancamentosEnum[key]);
  statusConciliacaos = Object.keys(StatusConciliacaoEnum).map(key => StatusConciliacaoEnum[key]);
  fileToUpload: File = null;
  usuariosmonitor: UsuarioMonitor[];
  periodo: string;
  periodoate: string;
  loadingERP: boolean = true;
  perfil: string = '';
  todosConciliacao: any[];
  qtdConciliacaoZero: number = 0;
  qtdConciliacaoDifMaior: number = 0;
  qtdConciliacaoSemLancamento: number = 0;
  qtdConciliacaoDifMenor: number = 0;
  qtdConciliacaoTodos: number = 0;
  adquirentes: any[];
  adquirente: string;
  statusConciliacao: string;


  valConciliacaoZero = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };
  valConciliacaoDifMaior = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };
  valConciliacaoDifMenor = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };
  valConciliacaoSemLancamento = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };
  valConciliacaoTodos = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private cadastrosService: CadastrosService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private histconciiacaoService: ConciliacaoService,
    private crudService: CrudService
  ) {

  }

  exportExcel() {

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.histconciiacao);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'historico_conciliação');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getPerfil() {
    const user = JSON.parse(localStorage.getItem('userMonitorOmnipos'));

    const sub = this.crudService.findByGeneric('usuarios', 'id', user.id ).subscribe(res => {
      if (res.length > 0) {
        this.perfil = res[0].perfil;
      }
      sub.unsubscribe();
    });
  }

  ngOnInit() {
    this.getusuariosmonitors();
    this.getPerfil();
    this.getAdquirentes();

  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }

  importConciliacao() {

    if (!this.periodo) {
      this.toastr.warning('Período de não informado', 'ATENÇÃO');
    } else  if (!this.periodoate) {
      this.toastr.warning('Período até não informado', 'ATENÇÃO');
    } else if (!this.origemLancamento) {
      this.toastr.warning('Origem do lançamento não informado', 'ATENÇÃO');
    } else if (!this.adquirente) {
            this.toastr.warning('Informe o adquirente!', 'ATENÇÃO');
    } else {
      let cliente = '';
      const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
      for (const u of this.usuariosmonitor) {
        if (u.email === user.email) {
          cliente = u.cliente;
        }
      }
      this.loadingERP = true;
      this.histconciiacao = [];
      this.todosConciliacao = [];

        this.spinner.show();
      // tslint:disable-next-line:max-line-length
      console.log( "statusConciliacao= "  + this.statusConciliacao) ;
        const sub = this.histconciiacaoService.getHistorico( this.origemLancamento, this.periodo, this.periodoate, cliente, this.adquirente, this.statusConciliacao).subscribe(data => {

          this.extracted(data);

          sub.unsubscribe();
        }, error => {
          this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
          console.log(error);
          this.spinner.hide();
        });

      // }
    }

  }


  async getAdquirentes() {

    this.adquirentes = [];

    if (this.movSub4 && this.movSub4.closed === false) {
      this.movSub4.unsubscribe();
    }

    if (!this.movSub4 || this.movSub4.closed) {

      this.movSub4 = this.cadastrosService.listar('adquirente/list').subscribe(
        (result: any) => {
          console.log( result );
          this.adquirentes =  result;
          this.adquirentes.push({ 'id' : 'Todos', 'descricao': 'Todos'});


       }, error => {
            console.log(error);
            this.spinner.hide();
       });

    }


  }




  calculaResumo() {


    this.qtdConciliacaoZero = 0;
    this.qtdConciliacaoDifMaior = 0;
    this.qtdConciliacaoSemLancamento = 0;
    this.qtdConciliacaoDifMenor = 0;
    this.qtdConciliacaoTodos = 0;

    this.valConciliacaoZero = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };
    this.valConciliacaoDifMaior = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };
    this.valConciliacaoDifMenor = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };
    this.valConciliacaoSemLancamento = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };
    this.valConciliacaoTodos = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };


    for (const c of this.todosConciliacao) {
      if (c.diferenca === 0) {
        this.qtdConciliacaoZero += 1;
        this.valConciliacaoZero.valDif += c.diferenca;
        this.valConciliacaoZero.valTotal += c.valor_liquido_parcela;
        this.valConciliacaoZero.valParcelas += c.valor_pago;
      }

      if (c.diferenca > 0 && c.diferenca <= c.ajuste_permitido) {
        this.qtdConciliacaoDifMenor += 1;
        this.valConciliacaoDifMenor.valDif += c.diferenca;
        this.valConciliacaoDifMenor.valTotal += c.valor_liquido_parcela;
        this.valConciliacaoDifMenor.valParcelas += c.valor_pago;
      }

      if (c.diferenca > c.ajuste_permitido) {
        this.qtdConciliacaoDifMaior += 1;
        this.valConciliacaoDifMaior.valDif += c.diferenca;
        this.valConciliacaoDifMaior.valTotal += c.valor_liquido_parcela;
        this.valConciliacaoDifMaior.valParcelas += c.valor_pago;
      }

      if (!c.valorParcelaExterno ) {
        this.qtdConciliacaoSemLancamento += 1;
        this.valConciliacaoSemLancamento.valDif += c.diferenca;
        this.valConciliacaoSemLancamento.valTotal += c.valor_liquido_parcela;
        this.valConciliacaoSemLancamento.valParcelas += c.valor_pago;
      }

      this.qtdConciliacaoTodos += 1;
      this.valConciliacaoTodos.valDif += c.diferenca;
      this.valConciliacaoTodos.valTotal += c.valor_liquido_parcela;
      this.valConciliacaoTodos.valParcelas += c.valor_pago;
    }

  }

  diferencaZero() {
    this.histconciiacao = [];
    for (const c of this.todosConciliacao) {
      if (c.diferenca === 0) {
        this.histconciiacao.push(c);
      }
    }
  }

  diferencaMenorPermitido() {
    this.histconciiacao = [];
    for (const c of this.todosConciliacao) {
      if (c.diferenca > 0 && c.diferenca <= c.ajuste_permitido) {
        this.histconciiacao.push(c);
      }
    }
  }

  diferencaMaiorPermitido() {
    this.histconciiacao = [];
    for (const c of this.todosConciliacao) {
      if (c.diferenca > c.ajuste_permitido) {
        this.histconciiacao.push(c);
      }
    }
  }

  todos() {
    this.histconciiacao =  this.todosConciliacao;
  }

  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  async extracted(data) {

    if (data && data.length > 0) {

      this.histconciiacao = data;
      this.todosConciliacao = data;
      this.calculaResumo();
      await this.spinner.hide();
      this.loadingERP = false;

      for (const c of this.histconciiacao) {
        c.selecionado = false;
      }

    } else {
      this.toastr.warning('Não foi localizado nenhum histórico para esse período!', 'ATENÇÃO');
      this.histconciiacao = [];
      this.todosConciliacao = [];
      await this.spinner.hide();
      this.loadingERP = false;
    }

  }

  async getusuariosmonitors() {

    this.usuariosmonitor = [];

    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed) {

      this.spinner.show();

      this.movSub = this.crudService.listarByFiltro('usuarios', null).subscribe(
        (result: any) => {

          this.usuariosmonitor = result;
          //console.log(result);

          this.spinner.hide();

        }, error => {
          console.log(error);
          this.spinner.hide();
        });

    }


  }

  selecionaConciliacao(item: any) {
    item.selecionado = !item.selecionado;
  }

  verMsg(msg, status) {

    if (status === 'PROCESSADO') {
      this.toastr.info(msg, 'Mensagem');
    } else if (status === 'ERRO') {
      this.toastr.error(msg, 'Mensagem');
    }


  }

}


