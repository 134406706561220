import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { TipoProcessamento } from '../../../model/tipoprocessamento';
import { CadastrosService } from '../../../services/cadastros.service';

@Component({
  selector: 'app-detalhes-tipoprocessamento',
  templateUrl: 'tipoprocessamento-detalhes.component.html'
})
export class TipoProcessamentoDetalhesComponent implements OnInit{

  @ViewChild('modaltipoprocessamento', {static: false}) public modaltipoprocessamento: ModalDirective;

  sub: boolean = false;
  _tipoprocessamento: TipoProcessamento = new TipoProcessamento();
  @Output() loadtipoprocessamento = new EventEmitter();
  titulo: string = "Novo Tipo de Processamento";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  movSub: Subscription;

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }

  ngOnInit() {

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesTipoProcessamento.subscribe(
        (p: TipoProcessamento) => {

          if(p == null){
            this.titulo = "Novo Tipo de Processamento"
            this._tipoprocessamento = new TipoProcessamento();
          }else{
            this.titulo = "Editar Tipo de Processamento"
            this._tipoprocessamento = p;
          }

          this.limpaModal();

          this.modaltipoprocessamento.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  hideModal(){
    this.modaltipoprocessamento.hide();
    this.loadtipoprocessamento.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar Tipo de Processamento"){
      this.atualizar();
    }else{
      this.salvar();
    }

    form.reset();
  }

  salvar(){

    let objtipoprocessamento = JSON.parse(JSON.stringify(this._tipoprocessamento));

    this.cadastrosService.salvar("tipoprocessamento/insert", objtipoprocessamento).subscribe(
      (result) =>{

        this.toastr.success('Tipo de Processamento cadastrado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );



  }

  atualizar(){

    let objtipoprocessamento = JSON.parse(JSON.stringify(this._tipoprocessamento));

    this.cadastrosService.atualizar("tipoprocessamento/update", objtipoprocessamento, objtipoprocessamento.id).subscribe(
      (result) =>{

        this.toastr.success('Tipo de Processamento atualizado com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );
  }
}
