
export class ModeloMediador {

    public id: string;
    public nome: string;

    constructor(){
        
    }
}
