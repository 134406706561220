import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as FileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Table } from 'primeng/table';
import { StepsModule } from 'primeng/steps';
import {MenuItem} from 'primeng/api';;
import { Subscription } from 'rxjs';
import { User } from '../../auth/user.model';
import { ConciliacaoArq } from '../../model/conciliacaoarq';
import { UsuarioMonitor } from '../../model/usuarioMonitor';
import { CadastrosService } from '../../services/cadastros.service';
import { ConciliacaoService } from '../../services/conciliacao.service';
import { CrudService } from '../../services/crudService';
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';

@Component({
  selector: 'app-conciliacao',
  templateUrl: './conciliacao.component.html',
  styleUrls: ['./conciliacao.component.scss']
})
export class ConciliacaoComponent implements OnInit, OnDestroy {

  @ViewChild('dt', { static: false }) table: Table;


  movSub: Subscription;
  movSub2: Subscription;
  movSub3: Subscription;
  movSub4: Subscription;
  movSub5: Subscription;
        movSubStatus: Subscription;
      conciliacao: any[];
  lancamentos: any[];
  conciliacaoArq: ConciliacaoArq;
  conciliacaoStatus: any[];
  fileToUpload: File = null;
  usuariosmonitor: UsuarioMonitor[];
  periodo: string;
  periodoate: string;
  periodoLancamento: string;
  periodoAteLancamento: string;
  loadingERP: boolean = true;
  loadingSAP: boolean = false;
  perfil: string = '';
  cliente: string = '';
  todosConciliacao: any[];
  qtdConciliacaoZero: number = 0;
  qtdConciliacaoDifMaior: number = 0;
  qtdConciliacaoSemSAP: number = 0;
  qtdConciliacaoSemLancamento: number = 0;
  qtdConciliacaoDifMenor: number = 0;
  qtdConciliacaoTodos: number = 0;
  activeIndex: number = 0;
  tentativaStatus: number = 0;
   ind : number = 0;
  tipoDoc: any;
  tableAtual: string;
  processando: boolean = false;
  _selecionaTodos: boolean = false;
  exibeStatusArquivo: string;
  adquirentes: any[];
  adquirente: string;
  adqTodos: boolean = false;
  items: MenuItem[];
  interval: any;



  valConciliacaoZero = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };


  valConciliacaoDifMaior = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };
  valConciliacaoSemSAP = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };
  valConciliacaoSemLancamento = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };
  valConciliacaoDifMenor = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };
  valConciliacaoTodos = {
    valTotal: 0,
    valParcelas: 0,
    valDif: 0
  };
  razonete = {
    qtdSelecionada: 0,

    valCConciliar: 0,
    valCComissao: 0,
    valCDif:0 ,
    valCImposto: 0,
    valCSubTotal:0,

    valDConciliar: 0,
    valDComissao: 0,
    valDDif:0 ,
    valDImposto: 0,
    valDSubTotal:0,

    valTotal: 0

  };



  sub: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private modalService: ModalService,
    private cadastrosService: CadastrosService,
    private toastr: ToastrService,
    private confirmationDialogService: ConfirmationDialogService,
    private conciliacaoService: ConciliacaoService,
    private crudService: CrudService
  ) {
    this.tipoDoc =  'RV';
    this.exibeStatusArquivo = '';
  }

  exportExcel() {

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.conciliacao);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'conciliação');
    });
  }

  exportExcelLanc() {

    import('xlsx').then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.lancamentos);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, 'lancamentos');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  getPerfil() {
    const user = JSON.parse(localStorage.getItem('userMonitorOmnipos'));

    const sub = this.crudService.findByGeneric('usuarios', 'id', user.id ).subscribe(res => {
      if (res.length > 0) {
        this.perfil = res[0].perfil;
        this.cliente = res[0].cliente;

        const _data = localStorage.getItem('dataConciliacao');

        if (_data) {

          const today = new Date(this.periodoate);
          this.periodoate = _data.split('.').join('-');

          if (this.periodoate) {

            const d = new Date(this.periodoate);
            d.setDate(d.getDate() - 30);
            this.periodo = d.toISOString().substring(0, 10);

            // this.importConciliacao();
          } else {

            this.periodoate = today.toISOString().substring(0, 10);

            const d = new Date(this.periodoate);
            d.setDate(d.getDate() - 30);
            this.periodo = d.toISOString().substring(0, 10);
          }
        } else {

          const today = new Date();
          this.periodoate = today.toISOString().substring(0, 10);

          const d = new Date(this.periodoate);
          d.setDate(d.getDate() - 30);
                this.periodo = d.toISOString().substring(0, 10);

        }

      }
      sub.unsubscribe();
    });
  }

  ngOnInit() {
    this.getusuariosmonitors();
    console.log("#####################1");

    this.getPerfil();
    console.log("#####################2");

    this.getAdquirentes();
    this.limpaConcliacaoArq();

    this.items = [
                {label: 'Arquivo Recebido'},
                {label: 'Gerando Lançamentos'},
                {label: 'Gravando Lançamentos'},
                {label: 'Encaminhado para Conciliação'},
                {label: 'Gravando Conciliação'},
                {label: 'Finalizado com sucesso'}
            ];


    if (this.movSub2 && this.movSub2.closed === false) {
        this.movSub2.unsubscribe();
    }
     //   console.log("#####################3");


    if (!this.movSub2 || this.movSub2.closed) {

   //  console.log("#####################4");

      this.movSub2 = this.modalService.modalConciliacaoRetorno.subscribe(
        (obj: any) => {

          if (this.processando) {
            this.finalizaProcessamento(obj.registros, obj.justificativa, obj.precisaJustificativa);
          }


        }, error => {
          console.log(error.message);
        }
      );

    }

    if (this.movSub3 && this.movSub3.closed === false) {
      this.movSub3.unsubscribe();
    }

    if (!this.movSub3 || this.movSub3.closed) {


      this.movSub3 = this.modalService.modalLancamentoRetorno.subscribe(
        (obj: any) => {

          if (this.processando) {
            this.finalizaProcessamentoLanc(obj.registros);
          }


        }, error => {
          console.log(error.message);
        }
      );

    }

  }


stopInterval() {
  if (this.interval) {
    clearInterval(this.interval);
  }
}

  async getAdquirentes() {

    this.adquirentes = [];

    if (this.movSub4 && this.movSub4.closed === false) {
      this.movSub4.unsubscribe();
    }

    if (!this.movSub4 || this.movSub4.closed) {

      this.movSub4 = this.cadastrosService.listar('adquirente/list').subscribe(
        (result: any) => {
          console.log( result );
          this.adquirentes =  result;
          this.adquirentes.push({ 'id' : 'Todos', 'descricao': 'Todos'});
       }, error => {
            console.log(error);
            this.spinner.hide();
       });

    }


  }






  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    this.stopInterval();
    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }

    if (this.movSub2 && this.movSub2.closed === false) {
      this.movSub2.unsubscribe();
    }
  }

  handleFileInput(files: FileList) {
    if ( files.item(0).size/1000/1000 > 2) {
          this.toastr.error('Tamanho  do arquivo ' + (files.item(0).size/1000/1000).toFixed(2) + 'MB. Limite 2MB'   , 'ATENÇÃO');
          this.fileToUpload = null;
    } else {
      this.fileToUpload =  files.item(0);
    }
  }

  importConciliacao() {
    this.limpaConcliacaoArq ();
    if (!this.periodo || !this.periodoate) {
      this.toastr.warning('Data de refêrencia não informada', 'ATENÇÃO');
    } else {
      let cliente = '';
      const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
      for (const u of this.usuariosmonitor) {
        if (u.email === user.email) {
          cliente = u.cliente;
        }
      }

      this.loadingERP = true;
      this.loadingSAP = false;
      this.conciliacao = [];
      this.todosConciliacao = [];

        this.spinner.show();
     this.startIntervalUpload () ;
            const sub = this.conciliacaoService.buscaConciliacao(this.periodo, this.periodoate, cliente, this.tipoDoc, this.adquirente).subscribe(data => {
       // console.log("***************************** buscaConciliacao ");
      //console.log(data);
       // console.log("***************************** buscaConciliacao FIM ");

          this.extracted(data);

          sub.unsubscribe();
        }, error => {
          this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
          console.log(error);
          this.spinner.hide();
        });

    }

  }

  importConciliacaoByFile() {

    if (!this.periodo || !this.periodoate) {
      this.toastr.warning('Data de refêrencia não informada', 'ATENÇÃO');
    } else if (!this.adquirente) {
            this.toastr.warning('Informe o adquirente!', 'ATENÇÃO');
    } else {
      let cliente = '';
      const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
      for (const u of this.usuariosmonitor) {
        if (u.email === user.email) {
          cliente = u.cliente;
        }
      }

      this.loadingERP = true;
      this.loadingSAP = false;
      this.conciliacao = [];
      this.todosConciliacao = [];


   if (this.movSubStatus && this.movSubStatus.closed === false) {
      this.movSubStatus.unsubscribe();
   }
        console.log("***************************** fechou ");
        this.movSubStatus = null;

        this.spinner.show();
        console.log("***************************** chamou ");
        this.limpaConcliacaoArq();
        this.startIntervalUpload () ;
        const sub = this.conciliacaoService.postFile(this.periodo, this.periodoate, cliente, this.adquirente, this.fileToUpload).subscribe(data => {

        // this.extracted(data);


        this.fileToUpload = null;
        this.spinner.hide();
        this.toastr.success ('Sucesso', 'Upload do arquivo concluído.');

        sub.unsubscribe();
      }, error => {
        this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
        console.log(error);
        this.spinner.hide();
      });


    }

  }

 async startIntervalUpload () {
     console.log("Entrou startIntervalUpload");
    this.getStatusConciliacao();
    this.stopInterval();

     this.interval = setInterval(()=>{
           this.getStatusConciliacao();
         },10000);
 }


  importLancamentos() {

    if (!this.periodoLancamento || !this.periodoAteLancamento) {
      this.toastr.warning('Data de refêrencia não informada', 'ATENÇÃO');
    } else {
      let cliente = '';
      const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
      for (const u of this.usuariosmonitor) {
        if (u.email === user.email) {
          cliente = u.cliente;
        }
      }

      this.loadingERP = true;
      this.loadingSAP = false;
      this.lancamentos = [];

      this.spinner.show();
      const sub = this.conciliacaoService.buscaLancamentos(this.periodoLancamento, this.periodoAteLancamento, cliente).subscribe( data => {

        if (data && data.length > 0) {

          this.lancamentos = data;
          this.spinner.hide();

          for (const c of this.lancamentos) {
            c.selecionado = false;
            c.pedido = c.idConciliacao.pedido;
            if ( !c.total_parcelas  || c.total_parcelas === undefined ) {
                c.parcelas = c.idConciliacao.parcela  ;
            } else {
                c.parcelas = c.idConciliacao.parcela + '/' + c.total_parcelas;
            }
          }

        } else {
          this.toastr.warning('Não foi localizado nenhum lançamentp sem referência!', 'ATENÇÃO');
          this.lancamentos = [];
          this.spinner.hide();
        }


        sub.unsubscribe();
      }, error => {
        this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
        console.log(error);
        this.spinner.hide();
      });

    }

  }

  mudaAdquirente() {

    if ( this.adquirente === "Todos" ) {
      this.adqTodos = true ;
    } else {
      this.adqTodos = false ;
    }

  }


  calculaResumo() {


    this.qtdConciliacaoZero = 0;
    this.qtdConciliacaoDifMaior = 0;
    this.qtdConciliacaoSemSAP = 0;
    this.qtdConciliacaoSemLancamento = 0;
    this.qtdConciliacaoDifMenor = 0;
    this.qtdConciliacaoTodos = 0;

    this.valConciliacaoZero = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };
    this.valConciliacaoDifMaior = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };
    this.valConciliacaoSemSAP = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };
    this.valConciliacaoDifMenor = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };
    this.valConciliacaoSemLancamento = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };
    this.valConciliacaoTodos = {
      valTotal: 0,
      valParcelas: 0,
      valDif: 0
    };

    this.razonete = {
             qtdSelecionada: 0,

             valCConciliar: 0,
             valCComissao: 0,
             valCDif:0 ,
             valCImposto: 0,
             valCSubTotal:0,

             valDConciliar: 0,
             valDComissao: 0,
             valDDif:0 ,
             valDImposto: 0,
             valDSubTotal:0,

             valTotal: 0

           };

   // console.log( "######### todosConciliacao ##############");
   // console.log( this.todosConciliacao );

   // console.log( "######### todosConciliacao fim ##############");
    for (const c of this.todosConciliacao) {
      const _diferenca = c.diferenca > 0 ? c.diferenca : c.diferenca * -1;

      if (c.diferenca === 0 &&  c.nome_cliente  ) {
        this.qtdConciliacaoZero  += 1;
        this.valConciliacaoZero.valDif += c.diferenca;
        this.valConciliacaoZero.valTotal += c.valor_liquido_parcela;
        this.valConciliacaoZero.valParcelas += c.valor_pago;
      }


      if (_diferenca > 0 && _diferenca <= c.ajuste_permitido &&  c.nome_cliente  ) {
        this.qtdConciliacaoDifMenor += 1;
        this.valConciliacaoDifMenor.valDif += c.diferenca;
        this.valConciliacaoDifMenor.valTotal += c.valor_liquido_parcela;
        this.valConciliacaoDifMenor.valParcelas += c.valor_pago;
      }

      if (_diferenca > c.ajuste_permitido &&  c.nome_cliente  ) {
        this.qtdConciliacaoDifMaior += 1;
        this.valConciliacaoDifMaior.valDif += c.diferenca;
        this.valConciliacaoDifMaior.valTotal += c.valor_liquido_parcela;
        this.valConciliacaoDifMaior.valParcelas += c.valor_pago;
      }

      if (!c.valor_pago &&  c.nome_cliente  ) {
        this.qtdConciliacaoSemLancamento += 1;
        this.valConciliacaoSemLancamento.valDif += c.diferenca;
        this.valConciliacaoSemLancamento.valTotal += c.valor_liquido_parcela;
        this.valConciliacaoSemLancamento.valParcelas += c.valor_pago;
      }

      if ( !c.nome_cliente ) {
        this.qtdConciliacaoSemSAP += 1;
        this.valConciliacaoSemSAP.valDif += c.diferenca;
        this.valConciliacaoSemSAP.valTotal += c.valor_liquido_parcela;
        this.valConciliacaoSemSAP.valParcelas += c.valor_pago;
      }




      this.qtdConciliacaoTodos += 1;
      this.valConciliacaoTodos.valDif += c.diferenca;
      this.valConciliacaoTodos.valTotal += c.valor_liquido_parcela;
      this.valConciliacaoTodos.valParcelas += c.valor_pago;
    }

  }

  diferencaZero() {

    this._selecionaTodos = false;
    let _registros = 0;
    let itemRow = 0;

    for (const c of this.todosConciliacao) {
      c.selecionado = false;
    }

    this.todosConciliacao = this.todosConciliacao.sort(function(a, b) {
      if (a.idConciliacao.pedido < b.idConciliacao.pedido) {
        return -1;
      } else if (a.idConciliacao.pedido === b.idConciliacao.pedido) {
        return 0;
      } else {
        return 1;
      }
    });

    if (this.tableAtual !== '' && this.tableAtual !== 'diferencaZero' ) {
      this.table.first = 0;
      this.tableAtual = 'diferencaZero';
    }

    this.conciliacao = [];
    for (const c of this.todosConciliacao) {
      if (c.diferenca === 0) {

        if (itemRow >= this.table.first && _registros < this.table.rows) {
          _registros = _registros + 1;
        }
        this.conciliacao.push(c);

        itemRow++;
      }


    }

  }

  diferencaMenorPermitido() {

    this._selecionaTodos = false;
    let _registros = 0;
    let itemRow = 0;

    for (const c of this.todosConciliacao) {
      c.selecionado = false;
    }

    this.todosConciliacao = this.todosConciliacao.sort(function(a, b) {
      if (a.idConciliacao.pedido < b.idConciliacao.pedido) {
        return -1;
      } else if (a.idConciliacao.pedido === b.idConciliacao.pedido) {
        return 0;
      } else {
        return 1;
      }
    });

    if (this.tableAtual !== '' && this.tableAtual !== 'diferencaMenorPermitido' ) {
      this.table.first = 0;
      this.tableAtual = 'diferencaMenorPermitido';
    }

    this.conciliacao = [];
    for (const c of this.todosConciliacao) {
      const _diferenca = c.diferenca > 0 ? c.diferenca : c.diferenca * -1;
      if (_diferenca > 0 && _diferenca <= c.ajuste_permitido &&  c.nome_cliente ) {

        if (itemRow >= this.table.first && _registros < this.table.rows) {
          _registros = _registros + 1;
        }
        this.conciliacao.push(c);

        itemRow++;
      }
    }

  }

  diferencaMaiorPermitido() {

    this._selecionaTodos = false;
    let _registros = 0;
    let itemRow = 0;

    for (const c of this.todosConciliacao) {
      c.selecionado = false;
    }

    this.todosConciliacao = this.todosConciliacao.sort(function(a, b) {
      if (a.idConciliacao.pedido < b.idConciliacao.pedido) {
        return -1;
      } else if (a.idConciliacao.pedido === b.idConciliacao.pedido) {
        return 0;
      } else {
        return 1;
      }
    });

    if (this.tableAtual !== '' && this.tableAtual !== 'diferencaMaiorPermitido' ) {
      this.table.first = 0;
      this.tableAtual = 'diferencaMaiorPermitido';
    }

    this.conciliacao = [];
    for (const c of this.todosConciliacao) {
      const _diferenca = c.diferenca > 0 ? c.diferenca : c.diferenca * -1;
      if (_diferenca > c.ajuste_permitido  &&  c.nome_cliente ) {

        if (itemRow >= this.table.first && _registros < this.table.rows) {
          _registros = _registros + 1;
        }
        this.conciliacao.push(c);

        itemRow++;
      }
    }

  }

  diferencaSemLancamento() {

    this._selecionaTodos = false;
    let _registros = 0;
    let itemRow = 0;

    for (const c of this.todosConciliacao) {
      c.selecionado = false;
    }

    this.todosConciliacao = this.todosConciliacao.sort(function(a, b) {
      if (a.idConciliacao.pedido < b.idConciliacao.pedido) {
        return -1;
      } else if (a.idConciliacao.pedido === b.idConciliacao.pedido) {
        return 0;
      } else {
        return 1;
      }
    });

    if (this.tableAtual !== '' && this.tableAtual !== 'diferencaSemLancamento' ) {
      this.table.first = 0;
      this.tableAtual = 'diferencaSemLancamento';
    }

    this.conciliacao = [];
    for (const c of this.todosConciliacao) {
      const _diferenca = c.diferenca > 0 ? c.diferenca : c.diferenca * -1;
      if ( c.adquirente == null ) {

        if (itemRow >= this.table.first && _registros < this.table.rows) {
          _registros = _registros + 1;
        }
        this.conciliacao.push(c);

        itemRow++;
      }
    }

  }


  diferencaSemSAP() {

    this._selecionaTodos = false;
    let _registros = 0;
    let itemRow = 0;

    for (const c of this.todosConciliacao) {
      c.selecionado = false;
    }

    this.todosConciliacao = this.todosConciliacao.sort(function(a, b) {
      if (a.idConciliacao.pedido < b.idConciliacao.pedido) {
        return -1;
      } else if (a.idConciliacao.pedido === b.idConciliacao.pedido) {
        return 0;
      } else {
        return 1;
      }
    });

    if (this.tableAtual !== '' && this.tableAtual !== 'diferencaSemSAP' ) {
      this.table.first = 0;
      this.tableAtual = 'diferencaSemSAP';
    }

    this.conciliacao = [];
    for (const c of this.todosConciliacao) {
      if ( c.nome_cliente  == null ) {

        if (itemRow >= this.table.first && _registros < this.table.rows) {
          _registros = _registros + 1;
        }
        this.conciliacao.push(c);

        itemRow++;
      }
    }

  }


  paginate(event) {

    let todosSelecionados = true;
    let _registros = 0;
    let itemRow = 0;

    for (const c of this.conciliacao) {
      if (itemRow >= this.table.first && _registros < this.table.rows) {
        if (!c.selecionado) {
          todosSelecionados = false;
        }

        _registros = _registros + 1;
      }

      itemRow++;
    }

   this._selecionaTodos = todosSelecionados;

  }

  selecionaTodos() {
    //console.log( "//////////////// ENTROU SELECIONA TODOS \\\\\\\\\\\\\\\\\\\\\\\\");

    if (this._selecionaTodos) {
      this._selecionaTodos = false;
    } else {
      this._selecionaTodos = true;
    }
 //console.log( "this._selecionaTodos");
 //console.log( this._selecionaTodos );

    let _registros = 0;
    let itemRow = 0;

    for (const c of this.conciliacao) {
      //console.log( "ENTROU FOR" );


        if (itemRow >= this.table.first && _registros < this.table.rows + this.table.first ) {
        //    console.log( "ENTROU ROW" );
        //    console.log( "c.diferenca>= 0" );
        //    console.log( c.diferenca>= 0 );
         //   console.log( c.diferenca <= c.ajuste_permitido );
         ///   console.log( (c.diferenca>= 0 && c.diferenca <= c.ajuste_permitido) );
///console.log(c.status_conciliacao);
            if (
            (  (c.diferenca>= 0 && c.diferenca <= c.ajuste_permitido && c.valor_pago > 0 )
            || ( this.perfil === 'ADMNISTRADOR CONCILIAÇÃO' && (c.status_transacao == 'PROCESSADO' || c.status_transacao == 'ANTECIPADO'))) && (c.status_conciliacao == 'PENDENTE' || c.status_conciliacao == 'ERRO')
           ) {
          //  console.log( "ENTROU PERFIL" );
                 c.selecionado = this._selecionaTodos;
           } else {
              c.selecionado = false;
           }

        }
        if ( this.table.rows)
        _registros = _registros + 1;
        itemRow++;
    }
     this.montaRazonete();

  }

  todos() {

    this._selecionaTodos = false;

    for (const c of this.todosConciliacao) {
      c.selecionado = false;
    }

    this.conciliacao =  this.todosConciliacao;

    if (this.tableAtual !== '' && this.tableAtual !== 'todos' ) {
      this.table.first = 0;
      this.tableAtual = 'todos';
    }
  }

  sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  async extracted(data) {

    if (data && data.length > 0) {

      this.conciliacao = data;
      this.todosConciliacao = data;
      this.calculaResumo();
      await this.spinner.hide();
      for (const c of this.conciliacao) {
        c.selecionado = false;
        c.pedido = c.idConciliacao.pedido;

            if ( !c.total_parcelas  || c.total_parcelas === undefined ) {
                c.parcelas = c.idConciliacao.parcela  ;
            } else {
                c.parcelas = c.idConciliacao.parcela + '/' + c.total_parcelas;
            }
      }

    } else {
      this.toastr.warning('Não foi localizado nenhum transação para conciliação!', 'ATENÇÃO');
      this.conciliacao = [];
      this.todosConciliacao = [];
      await this.spinner.hide();
    }

  }

  async getusuariosmonitors() {

    this.usuariosmonitor = [];

    if (this.movSub && this.movSub.closed === false) {
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed) {

      this.spinner.show();

      this.movSub = this.crudService.listarByFiltro('usuarios', null).subscribe(
        (result: any) => {

          this.usuariosmonitor = result;
       //   console.log(result);

          this.spinner.hide();

        }, error => {
          console.log(error);
          this.spinner.hide();
        });

    }


  }

  selecionaConciliacao(item: any) {
    item.selecionado = !item.selecionado;
    this.montaRazonete();
  }

  montaRazonete(){
      this.razonete = {
               qtdSelecionada: 0,

               valCConciliar: 0,
               valCComissao: 0,
               valCDif:0 ,
               valCImposto: 0,
               valCSubTotal:0,

               valDConciliar: 0,
               valDComissao: 0,
               valDDif:0 ,
               valDImposto: 0,
               valDSubTotal:0,

               valTotal: 0

      };

      for (const c of this.conciliacao) {
            if (c.selecionado) {
                  this.razonete.qtdSelecionada +=   1;
                   this.razonete.valCDif += c.diferenca * (-1);;
                   this.razonete.valCConciliar += c.valor_bruto_parcela;
                   this.razonete.valDConciliar += c.valor_pago * (-1);
                   this.razonete.valCComissao += 0;
                   this.razonete.valDComissao += c.tarifa_parcela * (-1);;
            }
      }

     this.razonete.valCSubTotal =  this.razonete.valCConciliar +  this.razonete.valCDif + this.razonete.valCComissao  + this.razonete.valCImposto;
     this.razonete.valDSubTotal =  this.razonete.valDConciliar +  this.razonete.valDDif + this.razonete.valDComissao  + this.razonete.valDImposto;

      this.razonete.valTotal = this.razonete.valCSubTotal + this.razonete.valDSubTotal;

  }



  verMsg(msg, status) {

    if (status === 'PROCESSADO') {
      // @ts-ignore
      this.toastr.info(msg, 'Mensagem', { closeButton: true, timeOut: 4000, enableHtml: true, allowHtml: true });
    } else if (status === 'ERRO') {
      // @ts-ignore
      this.toastr.error(msg, 'Mensagem', { closeButton: true, timeOut: 4000, enableHtml: true, allowHtml: true });
    }
  }

// *************************************************************
// * Cancelamento de concliação
// *************************************************************
  cancelar(conciliacaoSelecionada) {


    if (this.movSub5 && this.movSub5.closed === false) {
      this.movSub5.unsubscribe();
    }

    if (!this.movSub5 || this.movSub5.closed) {

      this.spinner.show();

      this.movSub = this.cadastrosService.salvar('conciliacao/cancelar', conciliacaoSelecionada).subscribe(function (result) {
                   this.toastr.success('Conciliação cancelada!', 'Sucesso!');
        }, error => {
          console.log(error);
        });
       this.spinner.hide();
       this.importConciliacao();
    }

  }

  processarLancamentos() {


    const lancamentosSelecionados = [];
    for (const c of this.lancamentos) {
      if (c.selecionado) {
        lancamentosSelecionados.push(c);
      }
    }

    if (lancamentosSelecionados.length === 0) {
      this.toastr.warning('Nenhuma transação selecionada!', 'ATENÇÃO');
    } else {

      this.processando = true;
      this.modalService.modalLancamento.emit(lancamentosSelecionados);


    }

  }

  processarConciliacao() {

    const conciliacoesSelecionadas = [];
    for (const c of this.conciliacao) {
        if (c.selecionado) {
          conciliacoesSelecionadas.push(c);
        }
    }

    if (conciliacoesSelecionadas.length === 0) {
      this.toastr.warning('Nenhuma transação selecionada!', 'ATENÇÃO');
    } else {

      this.processando = true;
      this.modalService.modalConciliacao.emit(conciliacoesSelecionadas);


    }


  }

  finalizaProcessamentoLanc(lancamentosSelecionados) {

    this.processando = false;

    this.loadingSAP = true;

    // this.loadingADQUIRENTE = false;
    // this.loadingPROC = false;
    this.loadingERP = false;

    if (!this.periodo) {
      this.toastr.warning('Data de refêrencia não informada', 'ATENÇÃO');
    } else {
      let cliente = '';
      const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
      for (const u of this.usuariosmonitor) {
        if (u.email === user.email) {
          cliente = u.cliente;
        }
      }

      this.spinner.show();
      // tslint:disable-next-line:max-line-length
      const sub = this.conciliacaoService.processaLancamentos(lancamentosSelecionados, this.periodo, cliente, user.email).subscribe(data => {

        this.spinner.show();
        // tslint:disable-next-line:no-shadowed-variable max-line-length
        const sub2 = this.conciliacaoService.buscaLancamentos(this.periodoLancamento, this.periodoAteLancamento, cliente).subscribe(data => {

          if (data && data.length > 0) {

            this.lancamentos = data;
            this.spinner.hide();

            for (const c of this.lancamentos) {
              c.selecionado = false;
              c.pedido = c.idConciliacao.pedido;

            if ( !c.total_parcelas  || c.total_parcelas === undefined ) {
                c.parcelas = c.idConciliacao.parcela  ;
            } else {
                c.parcelas = c.idConciliacao.parcela + '/' + c.total_parcelas;
            }
            }

          } else {
            this.toastr.warning('Não foi localizado nenhum lançamentp sem referência!', 'ATENÇÃO');
            this.lancamentos = [];
            this.spinner.hide();
          }


          sub2.unsubscribe();
        }, error => {
          this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
          console.log(error);
          this.spinner.hide();
        });

        sub.unsubscribe();
      }, error => {
        this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
        console.log(error);
        this.spinner.hide();
      });

    }

  }

  finalizaProcessamento(conciliacoesSelecionadas, justificativa, precisaJustificativa) {

    this.processando = false;

    let diferencaSemJustificativa = false;

    if (precisaJustificativa && (!justificativa || justificativa === '')) {
      diferencaSemJustificativa = true;
    }

      this.loadingSAP = true;

      // this.loadingADQUIRENTE = false;
      // this.loadingPROC = false;
      this.loadingERP = false;

      if (!this.periodo) {
        this.toastr.warning('Data de refêrencia não informada', 'ATENÇÃO');
      } else if (diferencaSemJustificativa) {
        this.toastr.warning('Necessário informar uma justificativa!', 'ATENÇÃO');
      } else {
        let cliente = '';
        const user: User = JSON.parse(localStorage.getItem('userMonitorOmnipos'));
        for (const u of this.usuariosmonitor) {
          if (u.email === user.email) {
            cliente = u.cliente;
          }
        }

        this.spinner.show();
        // tslint:disable-next-line:max-line-length
        const sub = this.conciliacaoService.processaConciliacoes(conciliacoesSelecionadas, this.periodo, cliente, user.email).subscribe(data => {

          this.spinner.show();
          // tslint:disable-next-line:no-shadowed-variable max-line-length
          const sub2 = this.conciliacaoService.buscaConciliacao(this.periodo, this.periodoate, cliente, this.tipoDoc, this.adquirente).subscribe(data => {

            this.extracted(data);

            this.toastr.success('Processamento finalizado.', 'Conciliação');

            this.loadingSAP = false;
            this.spinner.hide();
            sub2.unsubscribe();
          }, error => {
            this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
            console.log(error);
            this.spinner.hide();
          });

          sub.unsubscribe();
        }, error => {
          this.toastr.error('Erro', 'Não foi possivel buscar os dados.');
          console.log(error);
          this.spinner.hide();
        });

      }

  }



 async getStatusConciliacao() {
   console.log("Entrou getStatusConciliacao");
  // this.limpaConcliacaoArq();


    if (this.movSubStatus && this.movSubStatus.closed === false) {
      this.movSubStatus.unsubscribe();
    }

    if (!this.movSubStatus || this.movSubStatus.closed) {
        console.log("chamada listar");
        this.movSubStatus = this.cadastrosService.listar('conciliacaoarq/getRegistro?descontarMinuto=15&idAdquirente=' + this.adquirente + '&idCompany=' + this.cliente).subscribe(
            (result: ConciliacaoArq) => {
                console.log( result );
                this.conciliacaoArq =  result;
                if ( this.conciliacaoArq != null ) {
                    this.conciliacaoStatus = this.conciliacaoArq.conciliacaoStatusList;
                    this.atualizaItemsStatus();
                    this.tentativaStatus = 0;
                } else {
                    this.tentativaStatus++;
                    console.log( " tentativaStatus = " + this.tentativaStatus );
                    if ( this.tentativaStatus > 5 ) {
                        console.log( " PAROU stopInterval" );
                        this.stopInterval();
                    }

                }
       }, error => {
            console.log(error);
            this.spinner.hide();
       });
    }


 }


atualizaItemsStatus () {
    var vInd = 0;
    var vIndErro = 0;


     for (const cs of this.conciliacaoStatus) {
//        this.items[vInd] =  {label: cs.status };
        vInd += 1;
    }

    if ( vInd> 0 ) {
        this.activeIndex = vInd - 1;
    } else {
        this.activeIndex = 0;
    }

    var erro = false;
    vInd = 0;
     for (const cs of this.conciliacaoStatus) {
        if ( cs.erro  )  {
            erro = true;
            vIndErro = vInd;
        }
        vInd += 1 ;
    }

    if ( erro ) {
        this.items = this.items.slice ( 0, vIndErro + 1);
    }

}

limpaConcliacaoArq () {
    console.log ( "############ LIMPOU ##################" );
     this.conciliacaoArq = null;
        this.conciliacaoStatus = [];
        this.activeIndex = -1;
}

}


