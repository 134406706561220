import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Integracao } from '../../../model/integracao';
import { CadastrosService } from '../../../services/cadastros.service';

@Component({
  selector: 'app-detalhes-integracao',
  templateUrl: 'integracao-detalhes.component.html'
})
export class IntegracaoDetalhesComponent implements OnInit{

  @ViewChild('modalintegracao', {static: false}) public modalintegracao: ModalDirective;

  sub: boolean = false;
  _integracao: Integracao = new Integracao();
  @Output() loadintegracao = new EventEmitter();
  titulo: string = "Nova Integracao";
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  movSub: Subscription;
  tipointerface: any[];

  constructor(
    private modalService: ModalService,
    private toastr: ToastrService,
    private cadastrosService: CadastrosService) {
  }

  ngOnInit() {
       console.log ( '########### Chamou o INIT DE DETALHE');

    if(this.sub == false){

      this.sub = true;

      this.modalService.modalDetalhesIntegracao.subscribe(
        (p: Integracao) => {

          if(p == null){
            this.titulo = "Nova Integracao"
            this._integracao = new Integracao();
          }else{
            this.titulo = "Editar Integracao"
            this._integracao = p;
          }

          this.limpaModal();

          this.gettipointerfaces();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  async gettipointerfaces(){

    this.tipointerface = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.movSub = this.cadastrosService.listar("tipointerface/list").subscribe(
        (result: any) =>{

          this.tipointerface =  result;
          console.log(result);

       },error=>{
            console.log(error);
       });

    }


  }

  hideModal(){
    this.modalintegracao.hide();
    this.loadintegracao.emit();
  }

  limpaModal(){
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    if(this.titulo == "Editar Integracao"){
      this.atualizar();
    }else{
      this.salvar();
    }

    form.reset();
  }

  salvar(){

    let objintegracao = JSON.parse(JSON.stringify(this._integracao));

    this.cadastrosService.salvar("integracao/insert", objintegracao).subscribe(
      (result) =>{

        this.toastr.success('Integracao cadastrada com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );



  }

  atualizar(){

    let objintegracao = JSON.parse(JSON.stringify(this._integracao));

    this.cadastrosService.atualizar("integracao/update", objintegracao, objintegracao.id).subscribe(
      (result) =>{

        this.toastr.success('Integracao atualizada com sucesso!', 'Sucesso!');
        console.log(result);

        this.hideModal();

      },
      error=> {

        this.toastr.error(error.message, 'Erro!');
        console.log(error);

        this.hideModal();
      }

    );
  }
}
