import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { Integracao } from '../../model/integracao';
import { CadastrosService } from '../../services/cadastros.service';

@Component({
  selector: 'app-integracao',
  templateUrl: './integracao.component.html',
  styleUrls: ['./integracao.component.scss']
})


export class IntegracaoComponent implements OnInit {

  movSub: Subscription;
  integracao: any[];
  tipointerface: any[];
  fileReader: FileReader;
  fileToRead: File;

  constructor(
      private spinner: NgxSpinnerService,
      private modalService: ModalService,
      private cadastrosService: CadastrosService,
      private toastr: ToastrService,
      private confirmationDialogService: ConfirmationDialogService
  ){

  }

  ngOnInit() {
    this.getintegracaos();
  }

  async getintegracaos(){

    this.integracao = [];

    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed){

      this.spinner.show();

      this.movSub = this.cadastrosService.listar("integracao/list").subscribe(
        (result: any) =>{

          this.integracao =  result;
          console.log(result);

          this.spinner.hide();

       },error=>{
            console.log(error);
            this.spinner.hide();
       });

    }


  }

  ngOnDestroy(){
    if (this.movSub && this.movSub.closed == false){
      this.movSub.unsubscribe();
    }
  }

  novointegracao(){
        console.log ( 'Chamou o emit em novo');
        if (  this.modalService.modalDetalhesIntegracao != null ) {
            console.log ( "modalDetalhesIntegracao não é NULL" ) ;
            console.log (  this.modalService.modalDetalhesIntegracao) ;
        }

    this.modalService.modalDetalhesIntegracao.emit(null);
  }

  editar(integracao: Integracao){
    console.log ( 'Chamou o emit em editar');
       this.modalService.modalDetalhesIntegracao.emit(integracao);
  }

  excluir(integracao: Integracao){

    this.confirmationDialogService.confirm('Confirmar?', 'Realmente deseja excluir ?')
      .then((confirmed) =>{

        if(confirmed){
          this.cadastrosService.excluir("integracao/delete", integracao).subscribe(
            (result) =>{

              this.toastr.success('integracao excluído com sucesso!', 'Sucesso!');
              this.getintegracaos();
              console.log(result);

            },
            error=> {

              this.toastr.error(error.message, 'Erro!');
              console.log(error);

            }
          );
        }

      })
      .catch(() => {
        console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)')
      });

  }


}
