import {Component, ViewChild, OnInit, TemplateRef, EventEmitter, Output } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ModalService } from '../../../services/modal.service';
import * as $ from 'jquery';
import { Subscription, Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CadastrosService } from '../../../services/cadastros.service';
import { AuthResponseData, AuthService } from '../../../auth/auth.service';
import { CrudService } from '../../../services/crudService';
import { UsuarioMonitor } from '../../../model/usuarioMonitor';

@Component({
  selector: 'app-detalhes-usuariosmonitor',
  templateUrl: 'usuariosmonitor-detalhes.component.html'
})
export class UsuariosmonitorDetalhesComponent implements OnInit {

  @ViewChild('modalusuariosmonitor', {static: false}) public modalusuariosmonitor: ModalDirective;

  sub: boolean = false;
  _usuariosmonitor: UsuarioMonitor = new UsuarioMonitor();
  @Output() loadusuariosmonitor = new EventEmitter();
  titulo: string = 'Novo Usuario Monitor';
  movSubSalvar: Subscription;
  movSubAlterar: Subscription;
  isLoading = false;
  error: string = null;
  movSub: Subscription;
  perfils: any = [];
  clientes: any = [];
  notificacao: any = [];

  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private toastr: ToastrService,
    private cadastroService: CadastrosService,
    private crudService: CrudService) {
  }

  ngOnInit() {

    this.perfils = [];
    this.perfils.push({ 'id': 'MONITOR'});
    this.perfils.push({ 'id': 'ADMINISTRADOR'});
    this.perfils.push({ 'id': 'ADMNISTRADOR CONCILIAÇÃO'});
    this.perfils.push({ 'id': 'OPERADOR CONCILIAÇÃO'});

    this.notificacao = [];
    this.notificacao.push({ 'id': 'SIM'});
    this.notificacao.push({ 'id': 'NAO'});

    // tslint:disable-next-line:triple-equals
    if (this.sub == false) {

      this.sub = true;

      this.modalService.modalDetalhesUsuariosMonitor.subscribe(
        (p: UsuarioMonitor) => {

          if (p == null) {
            this.titulo = 'Novo Usuario Monitor';
            this._usuariosmonitor = new UsuarioMonitor();
          } else {
            this.titulo = 'Editar Usuario Monitor';
            this._usuariosmonitor = p;
          }

          this.getClientes();
          this.limpaModal();

          this.modalusuariosmonitor.show();

        }, error => {
          console.log(error.message);
        }
      );
    }

  }

  async getClientes() {

    this.clientes = [];

    // tslint:disable-next-line:triple-equals
    if (this.movSub && this.movSub.closed == false) {
      this.movSub.unsubscribe();
    }

    if (!this.movSub || this.movSub.closed) {

      this.movSub = this.cadastroService.listar('cliente/list').subscribe(
        (result: any) => {

          this.clientes =  result;
          console.log(result);

       }, error => {
            console.log(error);
       });

    }

  }

  hideModal() {
    this.modalusuariosmonitor.hide();
    this.loadusuariosmonitor.emit();
  }

  limpaModal() {
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  onSubmit(form: NgForm) {

    if (!form.valid) {
      return;
    }

    // tslint:disable-next-line:triple-equals
    if (this.titulo == 'Editar Usuario Monitor') {
      this.atualizar();
    } else {
      this.salvar();
    }

    form.reset();
  }

  salvar() {

    const objusuariosmonitor = JSON.parse(JSON.stringify(this._usuariosmonitor));

    let authObs: Observable<AuthResponseData>;

    authObs = this.authService.signup(this._usuariosmonitor.email, this._usuariosmonitor.senha);

    authObs.subscribe(
      resData => {
        console.log(resData);
        this.isLoading = false;
        this.error = null;

        objusuariosmonitor.id = resData.localId;

        this.salvaUsuario(objusuariosmonitor);
     },
      errorMessage => {
        this.toastr.warning(errorMessage, 'Atenção');
        console.log(errorMessage);
        this.error = errorMessage;
        this.isLoading = false;
      }
    );


  }

  salvaUsuario(objusuariosmonitor: any) {

    delete objusuariosmonitor.senha;
    this.crudService.salvar('usuarios', objusuariosmonitor);
    this.toastr.success('Usuario Monitor cadastrado com sucesso!', 'Sucesso!');
    this.hideModal();
  }

  atualizar() {

    const objusuariosmonitor = JSON.parse(JSON.stringify(this._usuariosmonitor));

    this.crudService.atualizar('usuarios', objusuariosmonitor);
    this.toastr.success('Usuario Monitor atualizado com sucesso!', 'Sucesso!');
    this.hideModal();
  }
}
