import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';

import { User } from './user.model';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseServiceProvider } from '../providers/firebase-service';
import { environment } from '../../environments/environment';
import {CrudService} from "../services/crudService";

export interface AuthResponseData {
  kind: string;
  idToken: string;
  email: string;
  refreshToken: string;
  expiresIn: string;
  localId: string;
  registered?: boolean;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;

  public afAuth: AngularFireAuth = this.fbService.auth;

  private KEY_API = environment.KEY_API.valueOf();  //Local

  constructor(
    private http: HttpClient,
    private router: Router,
    private fbService: FirebaseServiceProvider,
    private crudService: CrudService
  ) {}

  signup(email: string, password: string) {
    console.log( "##### signup ")
    return this.http
      .post<AuthResponseData>(
        'https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key='+this.KEY_API,
        {
          email: email,
          password: password,
          returnSecureToken: true
        }
      )
      .pipe(
        catchError(this.handleErrorByRest),
        tap(resData => {
          this.handleAuthentication(
            resData.email,
            resData.localId,
            resData.idToken,
            +resData.expiresIn
          );
        })
      );
  }

  verifyEmail(idToken: string) {
    return this.http
      .post<AuthResponseData>(
        'https://identitytoolkit.googleapis.com/v1/accounts:sendOobCode??key='+this.KEY_API,
        {
          requestType: 'VERIFY_EMAIL',
          idToken: idToken,
        }
      )
      .pipe(
        catchError(this.handleErrorByRest),
        tap(resData => {
         //console.log(resData);
        })
      );
  }

  login(email: string, password: string) : any{

    return this.afAuth.auth.signInWithEmailAndPassword(email, password);

  }

  alteraSenha(novasenha: string) : Promise<any>{

    let user = this.afAuth.auth.currentUser;
    return user.updatePassword(novasenha)

  }

  autoLogin() {

    const userData: {
      email: string;
      id: string;
      _token: string;
      _tokenExpirationDate: string;
    } = JSON.parse(localStorage.getItem('userMonitorOmnipos'));


    if (!userData) {
      return false;
    }

    const loadedUser = new User(
      userData.email,
      userData.id,
      userData._token,
      new Date(userData._tokenExpirationDate)
    );

    if (loadedUser.token) {
      this.user.next(loadedUser);
      const expirationDuration =
        new Date(userData._tokenExpirationDate).getTime() -
        new Date().getTime();

      this.autoLogout(expirationDuration);
      return true;
    }
  }

  logout() {
    this.user.next(null);
    this.router.navigate(['/login']);
    localStorage.removeItem('userMonitorOmnipos');
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

public handleAuthentication(
    email: string,
    userId: string,
    token: string,
    expiresIn: number
  ) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new User(email, userId, token, expirationDate);
    this.user.next(user);

    //console.log(JSON.stringify(user));

    localStorage.setItem('userMonitorOmnipos', JSON.stringify(user));

    const sub = this.crudService.findByGeneric('usuarios', 'id', user.id ).subscribe(res => {
      if (res.length > 0) {

        //console.log(JSON.stringify(res[0]));
        localStorage.setItem('userMonitorOmniposPerfil', JSON.stringify(res[0]));

      }
      sub.unsubscribe();
    });

  }

  public handleAuthenticationSub(
    email: string,
    userId: string,
    token: string,
    expiresIn: number
  ) {
    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new User(email, userId, token, expirationDate);
    this.user.next(user);

    //console.log(JSON.stringify(user));

    localStorage.setItem('userMonitorOmnipos', JSON.stringify(user));

    return this.crudService.findByGeneric('usuarios', 'id', user.id );

  }

  public handleErrorByRest(errorRes: HttpErrorResponse) {

    let errorMessage = 'Erro desconhecido!';
    if (!errorRes.error || !errorRes.error.error) {
      return throwError(errorMessage);
    }
    switch (errorRes.error.error.message) {
      case 'EMAIL_EXISTS':
        errorMessage = 'E-mail já cadastrado!';
        break;
      case 'EMAIL_NOT_FOUND':
        errorMessage = 'E-mail não existe.';
        break;
      case 'INVALID_PASSWORD':
        errorMessage = 'Senha incorreta.';
        break;
    }
    return throwError(errorMessage);
  }

  public handleError(errorRes: String) {
    let errorMessage = 'Erro desconhecido!';

    switch (errorRes) {
      case 'auth/user-not-found':
        errorMessage = 'E-mail não existe.';
        break;
      case 'auth/wrong-password':
        errorMessage = 'Senha incorreta.';
        break;
    }
    return errorMessage;
  }
}
